import { MouseEvent, useState } from 'react';

import { useAnalytics, useFeatures } from 'lib';
import { IoIosLink } from 'react-icons/io';
import {
  MdCheck,
} from 'react-icons/md';
import { toast } from 'react-toastify';
import {
  useLabels,
} from 'ui';
import {
  Button, IconButton, useTheme,
} from '@mui/material';

export const ShareButton = ({
  listingID,
  showAsButton = false,
}: {
  listingID: string,
  showAsButton?: boolean
}) => {
  const l = useLabels();
  const theme = useTheme();
  const analytics = useAnalytics();
  const [copied, setCopied] = useState(false);
  const { ...features } = useFeatures();

  const shareURL = `${window.location.origin}/marketplace/${listingID}?utm_source=share&register=true`;

  const handleClick = async (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    analytics.track('Button Clicked', {
      url: shareURL,
      listingId: listingID,
      buttonName: 'Share Listing',
    });

    await navigator.clipboard.writeText(shareURL);

    toast.success(l['listings.shareConfirm']);

    setCopied(true);

    setTimeout(() => {
      setCopied(false);
    }, 5000);
  };

  return showAsButton ? (
    <Button
      variant="outlined"
      startIcon={copied ? <MdCheck /> : <IoIosLink />}
      onClick={handleClick}
      disabled={!features.isShareListingEnabled}
    >
      {l.share}
    </Button>
  ) : (
    <IconButton
      onClick={handleClick}
      disabled={!features.isShareListingEnabled}
      sx={{
        background: 'white',
        position: 'absolute',
        top: 8,
        right: 64,
        zIndex: 1,
        '& > svg': {
          transition: '0.3s',
        },
        '&:active,&:focus,&:hover,&:disabled': {
          background: theme.palette.background.paper,
        },
        '&:hover > svg': {
          fill: theme.palette.primary.dark,
        },
      }}
    >
      {copied ? <MdCheck /> : <IoIosLink />}
    </IconButton>
  );
};
