import { MouseEvent, useState } from 'react';

import {
  APIUser, useAnalytics, useImpersonate, useInviteUser,
} from 'lib';
import { FaPlus } from 'react-icons/fa6';
import {
  MdOutlineLogin,
} from 'react-icons/md';
import { toast } from 'react-toastify';
import {
  InfoTooltip, InvertedButton, useLabels, YesOrNoLayout,
} from 'ui';
import {
  DialogContent, Menu, MenuItem, Modal, Typography,
} from '@mui/material';

export const InviteUserButton = ({ user, smsText }: { user: APIUser, smsText: string }) => {
  const l = useLabels();
  const analytics = useAnalytics();
  const { mutateAsync } = useInviteUser();
  const [confirmationEmailOpen, setConfirmationEmailOpen] = useState(false);
  const [confirmationSMSOpen, setConfirmationSMSOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleSubmit = async (communicationOption: 'EMAIL' | 'SMS') => {
    analytics.track('Button Clicked', {
      buttonName: 'Invite Prospect',
      ownerEmail: user.email,
      communicationOption,
    });

    try {
      await mutateAsync({ email: user.email, communicationOption });
      setConfirmationEmailOpen(false);
      setConfirmationSMSOpen(false);
    } catch (e) {
      console.error(e);
      toast.error(l['error.unknownError']);
    }
  };

  const handleClickInvite = (e: MouseEvent<HTMLButtonElement>) => {
    analytics.track('Button Clicked', {
      buttonName: 'Invite Owner - Open Menu',
      ownerEmail: user.email,
    });

    e.preventDefault();
    setAnchorEl(e.currentTarget);
  };

  const handleClickEmail = (e: MouseEvent<HTMLLIElement>) => {
    analytics.track('Button Clicked', {
      buttonName: 'Invite Owner - Email',
      ownerEmail: user.email,
    });

    e.stopPropagation();
    setConfirmationEmailOpen(true);
  };

  const handleClickSMS = (e: MouseEvent<HTMLLIElement>) => {
    analytics.track('Button Clicked', {
      buttonName: 'Invite Owner - SMS',
      ownerEmail: user.email,
    });

    e.stopPropagation();
    setConfirmationSMSOpen(true);
  };

  const confirmationModalEmail = (
    <Modal
      open={confirmationEmailOpen}
      onClose={() => setConfirmationEmailOpen(false)}
      sx={{
        zIndex: 1800,
      }}
    >
      <DialogContent>
        <YesOrNoLayout
          onClose={() => setConfirmationEmailOpen((c) => !c)}
          onSubmit={() => handleSubmit('EMAIL')}
          title={l.areYouSure}
          description={l['pm-dashboard.owner.confirmation']}
        />
      </DialogContent>
    </Modal>
  );

  const confirmationModalSMS = (
    <Modal
      open={confirmationSMSOpen}
      onClose={() => setConfirmationSMSOpen(false)}
      sx={{
        zIndex: 1800,
      }}
    >
      <DialogContent>
        <YesOrNoLayout
          onClose={() => setConfirmationSMSOpen((c) => !c)}
          onSubmit={() => handleSubmit('SMS')}
          title={`The following message will be sent to ${user.email}`}
          // eslint-disable-next-line react/no-danger
          description={<span dangerouslySetInnerHTML={{ __html: smsText }} />}
        />
      </DialogContent>
    </Modal>
  );

  return (
    <>
      <InvertedButton size="small" onClick={handleClickInvite} startIcon={<FaPlus />}>
        {l.invite}
      </InvertedButton>
      <Menu open={!!anchorEl} anchorEl={anchorEl} onClose={() => setAnchorEl(null)}>
        <MenuItem onClick={handleClickEmail}>
          <Typography variant="body2">
            {l.inviteOwnerByEmail}
          </Typography>
        </MenuItem>
        {!!user.phoneNumber && (
          <MenuItem onClick={handleClickSMS}>
            <Typography variant="body2">
              {l.inviteOwnerBySMS}
            </Typography>
          </MenuItem>
        )}
      </Menu>
      {confirmationModalEmail}
      {confirmationModalSMS}
    </>
  );
};

export const ImpersonateOwnerButton = ({ user }: { user: APIUser }) => {
  const l = useLabels();
  const analytics = useAnalytics();
  const { isLoading, mutateAsync: impersonate } = useImpersonate();

  const handleClick = async (event: MouseEvent) => {
    event.stopPropagation();

    analytics.track('User Impersonated', {
      actionName: l.view,
      userEmail: user.email,
    });

    try {
      const impersonationToken = await impersonate(user.email);
      window.open(`/?impersonation-token=${impersonationToken.data.token}`, '_blank');
    } catch (e) {
      console.error(e);
      toast.error(l['error.unknownError']);
    }
  };

  return (
    <InfoTooltip
      arrow
      isLight
      iconProps={{ disableRipple: true }}
      title={(
        <Typography variant="body2" sx={{ p: 1 }}>
          {l.viewOwnerDashboard}
        </Typography>
      )}
      icon={(
        <InvertedButton
          disabled={isLoading}
          variant="contained"
          size="small"
          startIcon={<MdOutlineLogin />}
          onClick={handleClick}
        >
          {l.view}
        </InvertedButton>
      )}
    />
  );
};
