import { sum, useAnalytics } from 'lib';
import {
  BoldTypography, DonutChart, formatNumberToCurrency, InfoTooltip, useLabels,
} from 'ui';
import {
  Card, CardContent, lighten, Stack, Typography, useTheme,
} from '@mui/material';

import { BlurredComponent } from './BlurredComponent';
import { DonutChartLegends } from './DonutChartLegends';
import { PMMetrics } from '../../../api/pm-metrics/types';

export const RevenueAtRisk = ({ metrics }: { metrics: PMMetrics }) => {
  const l = useLabels();
  const theme = useTheme();
  const analytics = useAnalytics();

  const configs = [
    {
      value: metrics.riskMetrics?.noRiskTotalValue ?? 0,
      count: metrics.riskMetrics?.totalNoRisk ?? 0,
      label: l['blanketOverview.revenueAtRisk.noRisk'],
      color: theme.palette.warning.main,
    },
    {
      value: metrics.riskMetrics?.needsAttentionTotalValue ?? 0,
      count: metrics.riskMetrics?.totalNeedsAttention ?? 0,
      label: l['blanketOverview.revenueAtRisk.atRisk'],
      color: lighten(theme.palette.primary.dark, 0.2),
    },
    {
      value: metrics.riskMetrics?.inProgressTotalValue ?? 0,
      count: metrics.riskMetrics?.totalInProgress ?? 0,
      label: l['blanketOverview.revenueAtRisk.inProgress'],
      color: theme.palette.error.main,
    },
    {
      value: metrics.riskMetrics?.resolvedTotalValue ?? 0,
      count: metrics.riskMetrics?.totalResolved ?? 0,
      label: l['blanketOverview.revenueAtRisk.resolved'],
      color: theme.palette.success.main,
    },
  ];

  return (
    <BlurredComponent
      isBlurred={sum(configs.map((c) => c.value)) === 0 || sum(configs.map((c) => c.count)) === 0}
      title={l['blanketOverview.chart.noData.title']}
      subtitle={l['blanketOverview.chart.noData.subtitle']}
    >
      <Card sx={{ height: '100%' }}>
        <CardContent>
          <Stack gap={3}>
            <Stack gap={1} direction="row" alignItems="center" justifyContent="space-between">
              <BoldTypography variant="h6">
                {l['blanketOverview.revenueAtRisk']}
              </BoldTypography>
              <InfoTooltip
                title={(
                  <Typography variant="body2" sx={{ p: 1 }}>
                    {l['blanketOverview.revenueAtRisk.tooltip']}
                  </Typography>
                )}
                arrow
                isLight
                isOutlined
                track={(value) => {
                  analytics.track('Tooltip Toggled', {
                    value,
                    tooltipName: 'Revenue at Risk Tooltip',
                  });
                }}
              />
            </Stack>
            <DonutChart
              tooltipAsDollars
              series={configs.map((c) => c.value)}
              labels={configs.map((c) => c.label)}
              colors={configs.map((c) => c.color)}
              scale={1}
              // eslint-disable-next-line react/no-unstable-nested-components
              formatter={() => (
                formatNumberToCurrency(sum(configs.map((c) => c.value)), 0, {
                  notation: 'compact',
                  compactDisplay: 'short',
                })
              )}
            >
              <Stack flexGrow={1}>
                <DonutChartLegends legends={configs} />
              </Stack>
            </DonutChart>
          </Stack>
        </CardContent>
      </Card>
    </BlurredComponent>
  );
};
