import { useMemo } from 'react';

import dayjs from 'dayjs';
import { EmailsSentToPMUsersByMonth, sum } from 'lib';
import { formatNumber, SemiBoldTypography, useLabels } from 'ui';

import { BlurredComponent } from './BlurredComponent';
import { sortTrend } from './trend';
import { PMMetrics } from '../../../api/pm-metrics/types';
import { TrendChartCard } from '../TrendChart';

export const CommsFromBlanket = ({ metrics }: { metrics: PMMetrics }) => {
  const l = useLabels();
  const datapointNum = 4;
  const comm = useMemo(() => {
    const dp: Omit<EmailsSentToPMUsersByMonth, '__typename'>[] = (
      (metrics.communicationMetrics?.emailsSentToPMUsersByMonth ?? []).sort(sortTrend).slice(0, datapointNum)
    );

    while (dp.length > 0 && dp.length < datapointNum) {
      const first = dp[0];

      dp.unshift({
        year: first.month === 1 ? first.year - 1 : first.year,
        month: first.month === 1 ? 12 : first.month - 1,
        emailsSent: 0,
      });
    }

    return dp;
  }, [metrics]);
  const series = comm.map((e) => e.emailsSent);
  // dayjs month is zero indexed
  const labels = comm.map((e) => dayjs().month(e.month - 1).year(e.year).format(e.year < dayjs().year() ? 'MMM YYYY' : 'MMM'));

  return (
    <BlurredComponent
      isBlurred={sum(series) === 0}
      title={l['blanketOverview.chart.noData.title']}
      subtitle={l['blanketOverview.chart.noData.subtitle']}
    >
      <TrendChartCard
        title={l['blanketOverview.commsFromBlanket']}
        tooltip={l['blanketOverview.commsFromBlanket.tooltip']}
        subtitle={(
          <SemiBoldTypography variant="body1" color="text.secondary">
            {formatNumber(sum(series), 0, { notation: 'compact', compactDisplay: 'short' })}
            {' '}
            {l.total}
          </SemiBoldTypography>
        )}
        series={series}
        labels={labels}
      />
    </BlurredComponent>
  );
};
