import { useMemo, useState } from 'react';

import {
  GrowthPolicy, isThisWeek, SuggestedPartnerStatus, SuggestedPartnerType, useAnalytics, useFeatures,
} from 'lib';
import { MdKeyboardArrowDown, MdLocationPin, MdPhone } from 'react-icons/md';
import { toast } from 'react-toastify';
import {
  BoldTypography,
  formatNumber,
  formatNumberToCurrency,
  hexToRGBA,
  InvertedButton,
  LazyImage,
  NewAgentLogo,
  Spinner,
  useConfetti,
  useLabels,
  YesOrNoLayout,
} from 'ui';
import {
  ButtonGroup, Card, darken, Grid, Link, Menu, MenuItem, Stack, Typography, useTheme,
} from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';

import { ActivityLevel } from './ActivityLevel';
import { AddToNetworkDialog } from './AddToNetworkDialog';
import { ContactDialog } from './ContactDialog';
import { ManagedPropertyTypes } from './ManagedPropertyTypes';
import { useAddToNetwork } from './useAddToNetwork';
import { WorkingCounties } from './WorkingCounties';
import { useUpdateSuggestedPartner } from '../../api/suggested-partners';
import { SuggestedPartner } from '../../api/suggested-partners/types';
import { PartnerEmail } from '../../components/partners/PartnerEmail';
import { QueryKey } from '../../types/enums';

const useSuggestedPartnerMetrics = (partner: SuggestedPartner) => {
  const l = useLabels();

  return useMemo(() => {
    if (partner.suggestedPartnerType === SuggestedPartnerType.PM) {
      return [
        {
          label: l['partners.card.workingCounties'],
          value: <WorkingCounties isBold id={partner.id} workingCounties={partner.workingCounties} />,
        },
        {
          label: l['partners.card.yearsInBusiness'],
          value: partner.experienceInYears || l.na,
        },
        {
          label: l['partners.card.propertyTypes'],
          value: <ManagedPropertyTypes isBold propertyTypes={partner.propertyTypes} id={partner.id} />,
        },
        {
          label: l['partners.card.doorCount'],
          value: partner.doorCount ? formatNumber(partner.doorCount, 0) : l.na,
        },
      ];
    }

    const [minPrice, maxPrice] = [
      formatNumberToCurrency(partner.minPrice ?? 0, 0, { notation: 'compact' }),
      formatNumberToCurrency(partner.maxPrice ?? 0, 0, { notation: 'compact' }),
    ];

    return [
      {
        label: l['partners.card.activityLevel'],
        value: <ActivityLevel activity={partner.activity} />,
      },
      {
        label: l['partners.card.yearsOfExperience'],
        value: partner.experienceInYears || l.na,
      },
      {
        label: l['partners.card.priceRange'],
        value: `${minPrice}-${maxPrice}`,
      },
      {
        label: l['partners.card.midRange'],
        value: formatNumberToCurrency(((partner.minPrice ?? 0) + (partner.maxPrice ?? 0)) / 2, 0, { notation: 'compact' }),
      },
    ];
  }, [partner, l]);
};

export const SuggestedPartnerCard = ({
  partner,
  growthPolicy = undefined,
}: {
  partner: SuggestedPartner,
  growthPolicy?: GrowthPolicy | null,
}) => {
  const l = useLabels();
  const theme = useTheme();
  const analytics = useAnalytics();
  const [contactDialogOpen, setContactDialogOpen] = useState(false);
  const [addToNetworkDialogOpen, setAddToNetworkDialogOpen] = useState(false);
  const [confirmAddToNetworkDialogOpen, setConfirmAddToNetworkDialogOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [invalidatingQueries, setInvalidatingQueries] = useState(false);
  const [generatedEmail, setGeneratedEmail] = useState(partner.contactEmailBody ?? '');
  const menuOpen = Boolean(anchorEl);
  const confetti = useConfetti();
  const { isLoading: isLoadingFeatures, ...features } = useFeatures();

  const queryClient = useQueryClient();
  const {
    mutateAsync: updateSuggestedPartnerStatus,
    isLoading: isUpdatingSuggestedPartnerStatus,
  } = useUpdateSuggestedPartner();
  const {
    mutateAsync: generateEmail,
    isLoading: isGeneratingEmail,
  } = useUpdateSuggestedPartner();
  const { addToNetwork, isLoading: addToNetworkLoading } = useAddToNetwork(partner);

  const isLoadingContact = isGeneratingEmail || isLoadingFeatures;
  const isLoadingChangeStatus = isUpdatingSuggestedPartnerStatus || invalidatingQueries || isLoadingFeatures;

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleClickOpenMenu = (e: React.MouseEvent<HTMLButtonElement>) => {
    analytics.track('Button Clicked', {
      buttonName: 'Suggested Partner - Open Menu',
      id: partner.id,
      email: partner.email,
      name: partner.name,
    });

    setAnchorEl(e.currentTarget);
  };

  const handleClickContact = async () => {
    analytics.track('Button Clicked', {
      buttonName: 'Suggested Partner - Contact',
      id: partner.id,
      status: partner.status,
      email: partner.email,
    });

    if (partner.contactEmailBody) {
      setContactDialogOpen(true);
      handleCloseMenu();
      return;
    }

    try {
      const res = await generateEmail({
        id: partner.id,
        generatesContactEmailBody: true,
      });
      setGeneratedEmail(res.data.contactEmailBody);
      setContactDialogOpen(true);
    } catch (e) {
      console.error(e);
      toast.error(l['error.unknownError']);
    }
    handleCloseMenu();
  };

  const handleClickAddToNetwork = async () => {
    analytics.track('Button Clicked', {
      buttonName: 'Suggested Partner - Add to network',
      id: partner.id,
      status: partner.status,
      email: partner.email,
    });

    if (partner.suggestedPartnerType === SuggestedPartnerType.PM) {
      setConfirmAddToNetworkDialogOpen(true);
    } else {
      setAddToNetworkDialogOpen(true);
    }

    handleCloseMenu();
  };

  const handleClickChangeStatus = async (status: SuggestedPartnerStatus) => {
    analytics.track('Button Clicked', {
      buttonName: 'Partners - Change Status',
      id: partner.id,
      email: partner.email,
      name: partner.name,
      status,
    });

    try {
      await updateSuggestedPartnerStatus({
        id: partner.id,
        status,
      });
      setInvalidatingQueries(true);
      await queryClient.invalidateQueries([QueryKey.SUGGESTED_PARTNERS]);
      setInvalidatingQueries(false);
      handleCloseMenu();
      toast.success(l['partners.successStatusChange']);
      if (status === SuggestedPartnerStatus.IN_PROGRESS && features.isFreemium) {
        confetti.trigger();
      }
    } catch (e) {
      console.error(e);

      toast.error(l['error.unknownError']);
    }
  };

  const metrics = useSuggestedPartnerMetrics(partner);

  const spinner = (
    <Stack justifyContent="center" alignItems="center" width="100%" minWidth={120}>
      &nbsp;
      <Spinner size={20} />
    </Stack>
  );

  return (
    <>
      <Card
        elevation={0}
        component={Stack}
        sx={{
          border: `1px solid ${theme.palette.divider}`,
          borderRadius: '10px',
          background: theme.palette.background.paper,
          height: '100%',
        }}
      >
        <Stack p={3} gap={4} flexGrow={1} justifyContent="space-between">
          <Stack direction="row" gap={2}>
            <div style={{
              position: 'relative',
              display: 'inline-block',
            }}
            >
              <LazyImage
                img={partner.avatar ? partner.avatar
                  : `https://ui-avatars.com/api/?name=${partner.firstName}+${partner.lastName}`}
                style={{
                  minHeight: 52,
                  minWidth: 52,
                  height: 52,
                  width: 52,
                  borderRadius: '50%',
                }}
              />
              {isThisWeek(new Date(partner.createdTime)) && (
                <NewAgentLogo
                  style={{
                    position: 'absolute',
                    bottom: -5,
                    left: 0,
                  }}
                />
              )}
            </div>
            <Stack direction="row" justifyContent="space-between" flexGrow={1} gap={2}>
              <Stack gap={2}>
                <BoldTypography variant="body1">
                  {partner.name}
                </BoldTypography>
                {partner.website && (
                  <Link
                    href={partner.website}
                    component="a"
                    variant="body2"
                    target="_blank"
                    sx={{ textDecoration: 'underline' }}
                  >
                    {partner.officeName}
                  </Link>
                )}
              </Stack>
              {partner.status !== SuggestedPartnerStatus.LOST && (
                <Stack>
                  <ButtonGroup>
                    {partner.status !== SuggestedPartnerStatus.NEW && (
                      <InvertedButton
                        variant="contained"
                        size="small"
                        disabled={addToNetworkLoading}
                        onClick={() => handleClickAddToNetwork()}
                      >
                        {addToNetworkLoading ? spinner : l['partners.addToMyNetwork']}
                      </InvertedButton>
                    )}
                    {partner.status === SuggestedPartnerStatus.NEW && (
                      <InvertedButton
                        variant="contained"
                        size="small"
                        disabled={isLoadingContact}
                        onClick={() => handleClickContact()}
                      >
                        {isLoadingContact ? spinner : l['partners.contact']}
                      </InvertedButton>
                    )}
                    <InvertedButton
                      variant="contained"
                      size="small"
                      onClick={(e) => handleClickOpenMenu(e)}
                      sx={{
                        p: '0 !important',
                        borderLeft: `1px solid ${darken(theme.palette.primary.dark, 0.1)} !important`,
                      }}
                    >
                      <MdKeyboardArrowDown size={24} />
                    </InvertedButton>
                  </ButtonGroup>
                  <Menu
                    anchorEl={anchorEl}
                    open={menuOpen}
                    onClose={() => setAnchorEl(null)}
                  >
                    {partner.status === SuggestedPartnerStatus.NEW && (
                      <MenuItem onClick={() => handleClickAddToNetwork()} disabled={addToNetworkLoading}>
                        {addToNetworkLoading ? spinner : l['partners.addToMyNetwork']}
                      </MenuItem>
                    )}
                    {partner.status !== SuggestedPartnerStatus.NEW && (
                      <MenuItem onClick={() => handleClickContact()} disabled={isLoadingContact}>
                        {isLoadingContact ? spinner : l['partners.contact']}
                      </MenuItem>
                    )}
                    {partner.status !== SuggestedPartnerStatus.IN_PROGRESS && (
                      <MenuItem
                        onClick={() => handleClickChangeStatus(SuggestedPartnerStatus.IN_PROGRESS)}
                        disabled={isLoadingChangeStatus}
                      >
                        {isLoadingChangeStatus ? spinner : l['partners.moveToInProgress']}
                      </MenuItem>
                    )}
                    <MenuItem
                      onClick={() => handleClickChangeStatus(SuggestedPartnerStatus.LOST)}
                      disabled={isLoadingChangeStatus}
                    >
                      {isLoadingChangeStatus ? spinner : l['partners.moveToNotRelevant']}
                    </MenuItem>
                  </Menu>
                </Stack>
              )}
            </Stack>
          </Stack>
          <Stack gap={2} pb={2}>
            <Stack direction="row" alignItems="center" justifyContent="space-between" gap={2} flexWrap="wrap">
              {partner.phone?.trim() && (
                <Stack direction="row" alignItems="center" gap={1}>
                  <MdPhone size={20} color={hexToRGBA(theme.palette.primary.main, 0.8)} />
                  <Typography variant="body2" color="secondary">
                    {partner.phone}
                  </Typography>
                </Stack>
              )}
              <PartnerEmail email={partner.email} />
            </Stack>
            {partner.address && (
              <Stack direction="row" alignItems="center" gap={1}>
                <MdLocationPin size={20} color={hexToRGBA(theme.palette.primary.main, 0.8)} />
                <Typography variant="body2" color="secondary">
                  {partner.address}
                </Typography>
              </Stack>
            )}
          </Stack>
        </Stack>
        <Grid container spacing={3} p={3} sx={{ borderTop: `1px solid ${theme.palette.divider}` }}>
          {metrics.map((metric) => (
            <Grid item xs={6} key={`${partner.email}-${metric.label}`}>
              <Stack height="100%" justifyContent="space-between">
                {['string', 'number'].includes(typeof metric.value) ? (
                  <BoldTypography variant="body1">
                    {metric.value}
                  </BoldTypography>
                ) : metric.value}
                <Typography variant="body2" color="secondary">
                  {metric.label}
                </Typography>
              </Stack>
            </Grid>
          ))}
        </Grid>
      </Card>
      <ContactDialog
        partner={{ ...partner, contactEmailBody: generatedEmail }}
        open={contactDialogOpen}
        onClose={() => setContactDialogOpen(false)}
      />
      <AddToNetworkDialog
        partner={partner}
        open={addToNetworkDialogOpen}
        onClose={() => setAddToNetworkDialogOpen(false)}
        defaultReferralAmount={growthPolicy?.pmReferralsFeeAmount ?? 0}
      />
      {confirmAddToNetworkDialogOpen && (
        <YesOrNoLayout
          title={l['partners.pm.confirmAddToNetwork']}
          description={l['partners.pm.confirmAddToNetwork.description']}
          onClose={() => setConfirmAddToNetworkDialogOpen(false)}
          onSubmit={addToNetwork}
          isLoading={addToNetworkLoading}
        />
      )}
    </>
  );
};
