export const listFavoritePropertyIDs = /* GraphQL */`
  query ListFavoritePropertyIDs($owner: String!) {
    listFavoritesByOwner(owner: $owner, limit: 5000) {
      items {
        marketplacePropertyFavoritesId
      }
    }
  }
`;

const listingPageMarketplaceProperty = /* GraphQL */ `
  id
  askingPrice
  createdTime
  description
  expenses {
    hoa
    insurance
    other
    tax
  }
  rent
  status
  propertyMarketplacePropertyId
  readPermissions
  propertyRental(sortDirection: DESC, limit: 1) {
    items {
      priceMean
      provider
    }
  }
  listingPictures(sortDirection: ASC, limit: 1) {
    items {
      rank
      url
    }
  }
  listingItems {
    items {
      id
      tags
      pm
      stateInsuranceFactor
      managementFee
      maintenance
      estimatedVacancy
      partialAddresses
      capRate
      grossYield
      managementFee
      insurance
    }
  }
  property {
    bathrooms
    bedrooms
    garages
    lotSize
    isVacant
    sqft
    yearBuilt
    units(limit: 5000) {
      items {
        id
        isVacant
      }
    }
    extendedAddress(limit: 5000) {
      items {
        lat
        lon
        state
        city
        street1
        zipCode
      }
    }
  }
  favorites(limit: 5000) {
    items {
      id
      owner
    }
  }
  address {
    street1
    street2
    zipCode
    city
    state
  }
`;

const searchFavoritesQuery = /* GraphQL */ `
  favoriteListingItems: searchMarketplacePropertyListingItems(
    filter: $favoritesFilter,
    limit: 1000,
    sort: $sort,
  ) {
    items {
      id
      pm
      partialAddresses
      tags
      stateInsuranceFactor
      capRate
      grossYield
      managementFee
      insurance
      property {
        ${listingPageMarketplaceProperty}
      }
    }
  }
`;

export const createListingItemsQuery = (queryFavorites?: boolean) => {
  const searchListingItemsQuery = /* GraphQL */ `
    query searchMarketplaceProperties(
      $limit: Int!,
      ${queryFavorites ? '$favoritesFilter: SearchableMarketplacePropertyListingItemFilterInput!,' : ''}
      $filter: SearchableMarketplacePropertyListingItemFilterInput!,
      $sort: [SearchableMarketplacePropertyListingItemSortInput!]!,
      $nextToken: String,
    ) {
      listingItems: searchMarketplacePropertyListingItems(filter: $filter, limit: $limit, sort: $sort, nextToken: $nextToken) {
        nextToken
        items {
          id
          pm
          partialAddresses
          tags
          stateInsuranceFactor
          capRate
          grossYield
          managementFee
          insurance
          property {
            ${listingPageMarketplaceProperty}
          }
        }
      }
      ${queryFavorites ? searchFavoritesQuery : ''}
    }
  `;

  return searchListingItemsQuery;
};

export const listRecommendedPropertiesQuery = /* GraphQL */ `
  query MyQuery($owner: String!, $pm: String!) {
    listRecommendationsByOwner(owner: $owner, limit: 100, sortDirection: DESC) {
      items {
        id
        marketplacePropertyRecommendationsId
        owner
        property {
          ${listingPageMarketplaceProperty}
        }
      }
    }
  listPmMarketplacePropertyRecommendationsByPm(pm: $pm, limit: 100, sortDirection: DESC) {
    items {
      id
      marketplacePropertyPmRecommendationsId
      pm
        property {
          ${listingPageMarketplaceProperty}
        }
      }
    }
  }
`;

export const searchListingItemsForSearchOptionsQuery = /* GraphQL */ `
  query searchMarketplaceProperties(
    $limit: Int!,
    $filter: SearchableMarketplacePropertyListingItemFilterInput!,
    $sort: [SearchableMarketplacePropertyListingItemSortInput!],
  ) {
    searchMarketplacePropertyListingItems(filter: $filter, limit: $limit, sort: $sort) {
      items {
        id
        partialAddresses
        property {
          id
        }
      }
    }
  }
`;

export const listMarketplacePropertiesForActivities = /* GraphQL */ `
 query listMarketplacePropertiesForActivities($owner: String, $nextToken: String) {
  listMarketplaceProperties(filter: {owner: {eq: $owner}, status: {ne: newLead}}, limit: 5000, nextToken: $nextToken) {
    nextToken
    items {
      id
      status
      propertyMarketplacePropertyId
      propertyRental {
        items {
          priceMean
        }
      }
      property{
        id
        extendedAddress(limit: 5000) {
          items {
            lat
            lon
            state
            city
            street1
            zipCode
          }
        }
      }
      address {
        street1
        street2
        zipCode
        city
        state
      }
      listingPictures(limit: 5000) {
         items {
            rank
            url
        }
      }
      owner
      offers(limit: 5000) {
        items {
          bid
          buyer
          seller
          status
          id
        }
      }
      askingPrice
    }
  }
}
`;

export const listOffersForActivities = /* GraphQL */ `
 query listOffersForActivities($owner: String, $nextToken: String) {
  listOffers(filter: {buyer: {eq: $owner}, status: {ne: newLead}}, nextToken: $nextToken, limit: 5000) {
    nextToken
    items {
      bid
      buyer
      id
      status
      seller
      property {
        id
        status
        propertyMarketplacePropertyId
        address {
          street1
          street2
          zipCode
          city
          state
        }
          listingPictures(limit: 5000) {
              items {
                  rank
                  url
              }
          }
        owner
        askingPrice
        property{
            id
            extendedAddress(limit: 5000) {
              items {
                lat
                lon
                state
                city
                street1
                zipCode
              }
            }
          }
      }
    }
  }
}
`;

export const getProperty = /* GraphQL */ `
  query GetProperty($id: ID!) {
    getMarketplaceProperty(id: $id) {
      id
      askingPrice
      createdTime
      description
      propertyRental(sortDirection: DESC, limit: 1) {
        items {
          priceMean
          provider
        }
      }
      expenses {
        hoa
        insurance
        other
        tax
      }
      rent
      status
      propertyMarketplacePropertyId
      readPermissions
      listingItems {
        items {
          id
          tags
          pm
          stateInsuranceFactor
          managementFee
          maintenance
          estimatedVacancy
          insurance
        }
      }
      listingPictures(limit: 5000) {
        items {
            rank
            url
        }
      }
      crime(limit: 5000) {
        items {
          property {
            county
            incidents
            national
          }
          all {
            county
            incidents
            national
          }
          violent {
            county
            incidents
            national
          }
          other {
            county
            incidents
            national
          }
        }
      }
      property {
        bathrooms
        bedrooms
        lotSize
        garages
        isVacant
        owner
        sqft
        units(limit: 5000) {
          items {
              id
              isVacant
          }
        }
        yearBuilt
        extendedAddress(limit: 5000) {
          items {
            lat
            lon
            state
            city
            street1
            street2
            zipCode
          }
        }
      }
      address {
        street1
        street2
        zipCode
        city
        state
      }
      favorites(limit: 5000) {
        items {
            id
            owner
        }
      }
    }
  }
`;

export const getGrowthPolicy = /* GraphQL */ `
  query GetGrowthPolicy($pm: ID!) {
    getGrowthPolicy(id: $pm) {
      enableOwnerReferralProgram
      ownerReferralsIncentive
    }
  }
`;
