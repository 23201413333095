import {
  ReactNode,
} from 'react';

import { InsightLevel, InsightType, OwnerRiskInsight } from 'lib';
import {
  Checkmark, CircledIcon, EmptyFullPageContainer,
  ExclamationMark,
  InfoTooltip, LightTooltip, Spinner, useLabels,
} from 'ui';
import {
  Box, lighten, Stack, Table, TableBody, TableCell, TableHead, TableRow, Typography,
  useTheme,
} from '@mui/material';
import { Row } from '@tanstack/react-table';

import { insightDescriptionMap } from './insightDescription';
import { useGetOwnerRisk } from '../../../api/owner-risk';
import { useGetOwnerPropertyData } from '../../../api/properties';
import {
  BasicOwnerRisk, ExpandedOwnerRisk, PropertyData,
} from '../../../api/properties/types';

const columnNames = [
  'Appreciation', 'Cashflow', 'NOI', 'Management Fee', 'Maintenance Cost',
  'Maintenance Events', 'Vacancy', 'Lease Expiration', 'Tenants Turnover',
] as const;

type InsightCategory = typeof columnNames[number];

const columnNameToCaseNames: Record<InsightCategory, string[]> = {
  Vacancy: ['vacancy'],
  'Tenants Turnover': ['turnoverTenants'],
  Cashflow: ['cashflow'],
  'Maintenance Events': ['maintenanceEvents'],
  'Lease Expiration': ['monthsToEndLease'],
  NOI: ['noi'],
  'Maintenance Cost': ['maintenance'],
  'Management Fee': ['managementFee'],
  Appreciation: ['appreciation'],
};

const getInsightCategory = (insight: OwnerRiskInsight): InsightCategory | undefined => {
  let category: InsightCategory | undefined;

  Object.entries(columnNameToCaseNames).forEach(([categoryName, caseNames]) => {
    if (caseNames.toString().toLowerCase().includes(insight.caseName?.toLowerCase() ?? '')) {
      category = categoryName as InsightCategory;
    }
  });

  return category;
};

const categoryToColumnWidth: Record<InsightCategory, number> = {
  Cashflow: 100,
  NOI: 100,
  'Maintenance Cost': 190,
  'Management Fee': 175,
  'Tenants Turnover': 200,
  'Lease Expiration': 180,
  'Maintenance Events': 210,
  Vacancy: 100,
  Appreciation: 100,
};

const InsightLevelWithTooltip = ({
  level,
  negative,
  children,
}: {
  level: InsightLevel,
  negative: boolean,
  children: ReactNode,
}) => {
  const theme = useTheme();
  const exclamationMarkStyle = {
    color: theme.palette.primary.contrastText,
  };
  const checkMarkStyle = {
    color: theme.palette.primary.contrastText,
    height: 10,
    width: 10,
  };

  return (
    <InfoTooltip
      arrow
      isLight
      title={children}
      iconProps={{ disableRipple: true }}
      icon={(
        <Stack direction="row" alignItems="center" gap={0.5}>
          {negative ? (
            <>
              <CircledIcon icon={<ExclamationMark {...exclamationMarkStyle} />} color={theme.palette.error.main} />
              <CircledIcon
                icon={<ExclamationMark {...exclamationMarkStyle} />}
                color={[InsightLevel.MEDIUM, InsightLevel.HIGH].includes(level) ? theme.palette.error.main : undefined}
              />
              <CircledIcon
                icon={<ExclamationMark {...exclamationMarkStyle} />}
                color={level === InsightLevel.HIGH ? theme.palette.error.main : undefined}
              />
            </>
          ) : (
            <>
              <CircledIcon icon={<Checkmark {...checkMarkStyle} />} color={theme.palette.success.main} />
              <CircledIcon
                icon={<Checkmark {...checkMarkStyle} />}
                color={[InsightLevel.MEDIUM, InsightLevel.HIGH].includes(level) ? theme.palette.success.main : undefined}
              />
              <CircledIcon
                icon={<Checkmark {...checkMarkStyle} />}
                color={level === InsightLevel.HIGH ? theme.palette.success.main : undefined}
              />
            </>
          )}
        </Stack>
      )}
    />
  );
};

const PropertyCategoryColumn = ({
  category,
  property,
  ownerRisk,
}: {
  category: InsightCategory,
  property: PropertyData,
  ownerRisk: ExpandedOwnerRisk,
}) => {
  const theme = useTheme();

  const propertyInsights = ownerRisk.insights?.filter((insight) => (
    insight.propertyID === property.id && getInsightCategory(insight) === category
  ));
  const positiveInsights = propertyInsights?.filter((insight) => insight.insightType === InsightType.POSITIVE) ?? [];
  const negativeInsights = propertyInsights?.filter((insight) => insight.insightType === InsightType.NEGATIVE) ?? [];

  const getInsightTitle = (insight: OwnerRiskInsight): string => {
    if (insightDescriptionMap.has(insight.caseName ?? '')) {
      const func = insightDescriptionMap.get(insight.caseName ?? '');

      return func?.(insight)?.title ?? '';
    }
    return insight.caseName ?? '';
  };

  const getInsightContext = (insight: OwnerRiskInsight): string[] => {
    if (insightDescriptionMap.has(insight.caseName ?? '')) {
      const func = insightDescriptionMap.get(insight.caseName ?? '');

      return func?.(insight)?.context ?? [];
    }
    return insight.caseName ? [insight.caseName] : [];
  };

  return (
    <Stack direction="row" alignItems="center" gap={3}>
      {positiveInsights.length > 0 && (
        <InsightLevelWithTooltip negative={false} level={positiveInsights[0]!.level}>
          {positiveInsights.map((insight) => {
            const title = getInsightTitle(insight);
            const context = getInsightContext(insight);

            return (
              <Stack key={`${insight.propertyID}-${insight.caseName}`} gap={1}>
                <Typography variant="body2" sx={{ color: theme.palette.text.secondary }}>
                  {title}
                </Typography>
                {context.map((c) => (
                  <Typography key={insight.id + c} variant="body1">
                    {c}
                  </Typography>
                ))}
              </Stack>
            );
          })}
        </InsightLevelWithTooltip>
      )}
      {negativeInsights.length > 0 && (
        <InsightLevelWithTooltip negative level={negativeInsights[0].level}>
          {negativeInsights.map((insight) => {
            const title = getInsightTitle(insight);
            const context = getInsightContext(insight);

            return (
              <Stack key={`${insight.propertyID}-${insight.caseName}`} gap={1}>
                <Typography variant="body2" sx={{ color: theme.palette.text.secondary }}>
                  {title}
                </Typography>
                {context.map((c) => (
                  <Typography key={insight.id + c} variant="body1">
                    {c}
                  </Typography>
                ))}
              </Stack>
            );
          })}
        </InsightLevelWithTooltip>
      )}
      {positiveInsights.length === 0 && negativeInsights.length === 0 && (
        <Box height="1px" width={20} sx={{ background: theme.palette.primary.main }} />
      )}
    </Stack>
  );
};

export const ExpandedRow = ({ row }: { row: Row<BasicOwnerRisk> }) => {
  const theme = useTheme();
  const l = useLabels();

  const { data: propertyData, isLoading: propertyDataLoading } = useGetOwnerPropertyData(row.original.ownerID);
  const { data: ownerRisk, isLoading: ownerRiskLoading } = useGetOwnerRisk(row.original.id);

  const isLoading = propertyDataLoading || ownerRiskLoading;

  const columnNameToTooltip: Record<InsightCategory, string> = {
    Cashflow: l['insight.name.cashflow.tooltip'],
    NOI: l['insight.name.noi.tooltip'],
    'Maintenance Cost': l['insight.name.maintenance_cost.tooltip'],
    'Management Fee': l['insight.name.management_fee.tooltip'],
    'Tenants Turnover': l['insight.name.tenants_turnover.tooltip'],
    'Lease Expiration': l['insight.name.lease_expiration.tooltip'],
    'Maintenance Events': l['insight.name.maintenance_events.tooltip'],
    Vacancy: l['insight.name.vacancy.tooltip'],
    Appreciation: l['insight.name.appreciation.tooltip'],
  };

  const bgColor = lighten(theme.palette.info.main, 0.88);

  return (
    <Stack sx={{ background: bgColor, maxWidth: '100%' }} pt={isLoading ? 3 : 0}>
      {isLoading ? (
        <EmptyFullPageContainer>
          <Box width="100%" height={40} position="relative">
            <Spinner size={20} />
          </Box>
        </EmptyFullPageContainer>
      ) : (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{
                width: 170,
                minWidth: 170,
                position: 'sticky',
                left: 0,
                zIndex: 1,
                background: bgColor,
              }}
              />
              {columnNames.map((columnName) => (
                <TableCell
                  key={columnName}
                  sx={{ width: categoryToColumnWidth[columnName], minWidth: categoryToColumnWidth[columnName] }}
                >
                  <Stack direction="row" alignItems="center" gap={3}>
                    <Typography variant="body3" sx={{ textTransform: 'uppercase' }}>
                      {columnName}
                    </Typography>
                    <InfoTooltip
                      isSmall
                      isLight
                      isOutlined
                      title={columnNameToTooltip[columnName]}
                      arrow
                    />
                  </Stack>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody sx={{ maxWidth: '100%', overflowX: 'auto' }}>
            {propertyData?.propertyData.map((property, i) => (
              <TableRow key={property.id}>
                <TableCell
                  sx={{
                    borderBottom: i < propertyData!.propertyData.length - 1 ? `1px solid ${theme.palette.divider}` : 'none',
                    position: 'sticky',
                    left: 0,
                    zIndex: 1,
                    background: bgColor,
                  }}
                >
                  <LightTooltip
                    title={(
                      <Typography variant="body2" sx={{ p: 1 }}>
                        {property.fullAddress}
                      </Typography>
                    )}
                    placement="top"
                    arrow
                  >
                    <Typography
                      variant="body2"
                    >
                      {property.street1}
                    </Typography>
                  </LightTooltip>
                </TableCell>
                {columnNames.map((columnName) => (
                  <TableCell
                    key={`${property.id}-${columnName}`}
                    sx={{
                      width: categoryToColumnWidth[columnName],
                      minWidth: categoryToColumnWidth[columnName],
                      borderBottom: i < propertyData!.propertyData.length - 1 ? `1px solid ${theme.palette.divider}` : 'none',
                    }}
                  >
                    <PropertyCategoryColumn
                      property={property}
                      ownerRisk={ownerRisk!}
                      category={columnName}
                    />
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
    </Stack>
  );
};
