import { useEffect, useState } from 'react';

import {
  SuggestedPartnerStatus, useAnalytics, useFeatures,
} from 'lib';
import { MdClose } from 'react-icons/md';
import { toast } from 'react-toastify';
import {
  BoldTypography, copyHtmlToRTF, InvertedButton, RichEditor, RichEditorInstance, Spinner, useConfetti, useLabels,
} from 'ui';
import {
  Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Stack, useTheme,
} from '@mui/material';
import { useSignal } from '@preact/signals-react';
import { useQueryClient } from '@tanstack/react-query';

import { useUpdateSuggestedPartner } from '../../api/suggested-partners';
import { SuggestedPartner } from '../../api/suggested-partners/types';
import { QueryKey } from '../../types/enums';

export const ContactDialog = ({
  partner,
  open,
  onClose,
}: {
  partner: SuggestedPartner,
  open: boolean,
  onClose: () => void,
}) => {
  const l = useLabels();
  const theme = useTheme();
  const analytics = useAnalytics();
  const queryClient = useQueryClient();
  const { mutateAsync: updateSuggestedPartner, isLoading: isUpdatingSuggestedPartner } = useUpdateSuggestedPartner();
  const [invalidatingQuery, setInvalidatingQuery] = useState(false);
  const editorState = useSignal<RichEditorInstance | null>(null);
  const confetti = useConfetti();
  const { isLoading: isLoadingFeatures, ...features } = useFeatures();

  const [emailContent, setEmailContent] = useState({
    html: '',
    text: '',
  });

  const isLoading = isUpdatingSuggestedPartner || invalidatingQuery || isLoadingFeatures;

  const handleClickCopyAndClose = async () => {
    analytics.track('Button Clicked', {
      buttonName: 'Copy and save',
      id: partner.id,
      email: partner.email,
      status: partner.status,
    });

    await copyHtmlToRTF(emailContent.html, emailContent.text);
    await updateSuggestedPartner({
      id: partner.id,
      status: SuggestedPartnerStatus.IN_PROGRESS,
    });
    setInvalidatingQuery(true);
    await queryClient.invalidateQueries([QueryKey.SUGGESTED_PARTNERS]);
    setInvalidatingQuery(false);
    toast.success(l['partners.contact.copyAndClose.success']);
    if (features.isFreemium) {
      confetti.trigger();
    }

    handleClickClose(null, 'buttonClick');
  };

  const handleClickClose = (e: any, reason: 'backdropClick' | 'escapeKeyDown' | 'buttonClick') => {
    if (reason === 'backdropClick') return;

    onClose();
  };

  useEffect(() => {
    if (open && editorState.value) {
      setEmailContent({
        html: partner.contactEmailBody ?? '',
        text: '',
      });
      editorState.value?.commands.setContent(partner.contactEmailBody ?? '');
    }
  }, [open, partner.contactEmailBody, editorState.value]);

  return (
    <Dialog open={open} onClose={handleClickClose} fullScreen={false} fullWidth maxWidth="md" disableEscapeKeyDown>
      <DialogTitle sx={{ borderBottom: `1px solid ${theme.palette.divider}`, py: 2 }}>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <BoldTypography variant="h6">
            {l['partners.contact']}
          </BoldTypography>
          <IconButton onClick={() => handleClickClose(null, 'buttonClick')}>
            <MdClose />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Stack mt={3} gap={4}>
          <RichEditor
            initialContent={emailContent.html}
            onContentChange={(html, text) => setEmailContent({ html, text })}
            onCreate={({ editor }) => {
              editorState.value = editor;
            }}
          />
        </Stack>
      </DialogContent>
      <DialogActions sx={{ borderTop: `1px solid ${theme.palette.divider}`, py: 3 }}>
        <Stack direction="row" justifyContent="space-between" width="100%" pt={3}>
          <Button variant="text" onClick={(e) => handleClickClose(e, 'buttonClick')} size="small">
            {l.cancel}
          </Button>
          <InvertedButton
            disabled={isLoading}
            onClick={handleClickCopyAndClose}
            size="small"
          >
            {isLoading ? (
              <>
                &nbsp;
                <Spinner size={20} />
              </>
            ) : l['partners.contact.copyAndClose']}
          </InvertedButton>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};
