import { useCallback, useMemo } from 'react';

import { jwtDecode } from 'jwt-decode';
import { useDescope, useSession, useUser } from '@descope/react-sdk';
import { useQuery } from '@tanstack/react-query';

import { useAppSettings } from '../api/app-settings';

export const impersonationToken = 'impersonation-token';

export const isImpersonationUser = () => sessionStorage.getItem(impersonationToken) !== null;

export type Claims = {
  isActAs: boolean
  isFirstLogin: boolean
};

export type Permissions = {
  viewDashboard: boolean
  viewMarketplace: boolean
  viewPMDashboard: boolean
  viewBilling: boolean
  viewLeads: boolean
  viewOBP: boolean
  viewReferralPartner: boolean
  viewReferralLeads: boolean
  viewRetain: boolean
  viewGrow: boolean
  viewB2CReferralProgram: boolean
  viewProspectLeads: boolean
  viewSuggestedPMs: boolean
  createAdminUser: boolean
};

export type User = {
  id: string
  realID: string
  name: string
  pm: string
  email: string
  givenName?: string
  familyName?: string
  picture?: string
  actAs: boolean
  smsConsent: boolean
  userProvidedPhone: string
  effectivePhone: string
};

type Auth = {
  isAuthenticated: boolean,
  user: User | undefined,
  refreshUser: () => Promise<void>,
  getAccessTokenSilently: () => Promise<string>;
  getIdTokenClaims: () => Promise<Claims>;
  logout: () => void;
};

export const useAuth = (): Auth => {
  const { data: appSettings } = useAppSettings();
  const { logout, me } = useDescope();
  const { sessionToken, isAuthenticated } = useSession();
  const { user } = useUser();

  const getAccessTokenSilently = useCallback(async () => {
    if (isImpersonationUser()) {
      return sessionStorage.getItem(impersonationToken) ?? '';
    }

    return sessionToken;
  }, [sessionToken]);

  const authUser = useMemo<User | undefined>(() => {
    if (!user) return undefined;

    const token = isImpersonationUser() ? sessionStorage.getItem(impersonationToken) : sessionToken;
    if (!token) return undefined;

    const claims = jwtDecode(token!) as any;
    const { pm, email, invitationEmail: realEmail } = claims;

    return {
      pm,
      email,
      id: `${pm}::${email!}`,
      realID: `${pm}::${realEmail!}`,
      name: user.name!,
      givenName: user.givenName,
      familyName: user.familyName,
      picture: user.picture,
      actAs: realEmail !== email,
      smsConsent: user.customAttributes?.smsConcent ?? false,
      userProvidedPhone: user.customAttributes?.userProvidedPhone ?? '',
      effectivePhone: user.customAttributes?.userProvidedPhone || user.phone || '',
    };
  }, [user, appSettings, sessionToken]);

  return {
    isAuthenticated,
    logout,
    user: authUser,
    refreshUser: async () => {
      await me();
    },
    getAccessTokenSilently,
    getIdTokenClaims: async () => {
      const claims = jwtDecode(sessionToken) as any;

      return {
        isActAs: !!user.customAttributes?.actAsEmail,
        isFirstLogin: claims?.firstLogin,
      };
    },
  };
};

export const usePermissions = () => {
  const { user } = useAuth();
  const { sessionToken } = useSession();
  const { logout } = useDescope();

  const pm = user?.pm;

  return useQuery(['permissions'], async (): Promise<Permissions> => {
    let { permissions } = jwtDecode(sessionToken) as any;

    if (isImpersonationUser()) {
      const decodedToken: any = jwtDecode(sessionStorage.getItem(impersonationToken) ?? '');
      permissions = decodedToken.permissions;
    }

    if (!permissions) {
      console.error('no permissions from token');

      await logout();

      return {
        viewDashboard: false,
        viewMarketplace: false,
        viewPMDashboard: false,
        viewBilling: false,
        viewLeads: false,
        viewOBP: false,
        viewReferralPartner: false,
        viewReferralLeads: false,
        viewRetain: false,
        viewGrow: false,
        viewB2CReferralProgram: false,
        viewProspectLeads: false,
        viewSuggestedPMs: false,
        createAdminUser: false,
      };
    }

    return {
      viewDashboard: permissions.includes('view-dashboard'),
      viewMarketplace: permissions.includes('view-marketplace'),
      viewPMDashboard: permissions.includes('view-pm-dashboard'),
      viewBilling: permissions.includes('view-billing'),
      viewLeads: permissions.includes('view-pm-leads-page'),
      viewOBP: permissions.includes('view-obp'),
      viewReferralPartner: permissions.includes('view-referral-partners'),
      viewReferralLeads: permissions.includes(`view-${pm}-leads`),
      viewRetain: permissions.includes('view-retain'),
      viewGrow: permissions.includes('view-grow'),
      viewB2CReferralProgram: permissions.includes('view-b2c-referral-program'),
      viewProspectLeads: permissions.includes(`view-${pm}-leads`),
      viewSuggestedPMs: permissions.includes('view-suggested-pms'),
      createAdminUser: permissions.includes('create-admin-user'),
    };
  }, { enabled: !!user });
};
