import { useNavigate } from 'react-router-dom';

import {
  config, useAnalytics, useAuth, useFeatures, useGetQuestions,
} from 'lib';
import { toast } from 'react-toastify';
import {
  BoldTypography, hexToRGBA, InvertedButton, useLabels,
} from 'ui';
import {
  Box, Skeleton, Stack, Typography, useMediaQuery, useTheme,
} from '@mui/material';

import { BuyBoxCard } from './styled';
import { DialogPurchaseQuestionnaire } from '../purchase-questionnaire';

type Props = {
  questionsLoading: boolean;
  questionsError: boolean;
  purchaseQuestionnaireExists: boolean;
  onClick: () => void;
};

const BuyBoxDesktop = ({
  questionsLoading,
  questionsError,
  purchaseQuestionnaireExists,
  onClick,
}: Props) => {
  const l = useLabels();
  const theme = useTheme();

  return (
    <BuyBoxCard component={Stack} elevation={0}>
      <Stack px={5} py={4} gap={2} flexGrow={1} justifyContent="space-between" zIndex={1}>
        <Stack>
          <BoldTypography variant="body1">
            {l['listings.marketplace.preferences']}
          </BoldTypography>
          <Typography variant="body3" sx={{ color: hexToRGBA(theme.palette.text.primary, 0.8) }} maxWidth="70%">
            {l['listings.marketplace.preferences.description']}
          </Typography>
        </Stack>
        <Box>
          <InvertedButton
            variant="contained"
            size="small"
            sx={{ width: questionsLoading ? '56%' : 'auto' }}
            title={questionsError ? l.errorMadeButtonDisabled : undefined}
            disabled={questionsLoading || questionsError}
            onClick={onClick}
          >
            {questionsLoading && !purchaseQuestionnaireExists && <Skeleton variant="text" width="100%" />}
            {!questionsLoading && purchaseQuestionnaireExists && l['listings.marketplace.preferences.edit']}
            {!questionsLoading && !purchaseQuestionnaireExists && l['listings.marketplace.preferences.add']}
          </InvertedButton>
        </Box>
      </Stack>
    </BuyBoxCard>
  );
};

const BuyBoxMobile = ({
  questionsLoading,
  questionsError,
  purchaseQuestionnaireExists,
  onClick,
}: Props) => {
  const l = useLabels();
  const theme = useTheme();

  const handleClick = () => {
    if (questionsError) {
      toast.error(l.errorMadeButtonDisabled);
    } else {
      onClick();
    }
  };

  return (
    <BuyBoxCard component={Stack} onClick={handleClick} elevation={0}>
      <Stack px={5} py={4} gap={2} flexGrow={1} justifyContent="space-between" zIndex={1}>
        <Stack>
          <BoldTypography variant="body1">
            {l['listings.marketplace.preferences']}
          </BoldTypography>
          <Typography variant="body3" sx={{ color: hexToRGBA(theme.palette.text.primary, 0.8) }} maxWidth="60%">
            {l['listings.marketplace.preferences.description']}
          </Typography>
        </Stack>
        <Box>
          <InvertedButton
            variant="contained"
            size="small"
            sx={{ width: questionsLoading ? '56%' : 'auto' }}
            disabled={questionsLoading}
          >
            {questionsLoading && !purchaseQuestionnaireExists && <Skeleton variant="text" width="100%" />}
            {!questionsLoading && purchaseQuestionnaireExists && l['listings.marketplace.preferences.edit']}
            {!questionsLoading && !purchaseQuestionnaireExists && l['listings.marketplace.preferences.add']}
          </InvertedButton>
        </Box>
      </Stack>
    </BuyBoxCard>

  );
};

export const BuyBox = ({
  purchaseQuestionnaireDialogOpen,
  setPurchaseQuestionnaireDialogOpen,
}: {
  purchaseQuestionnaireDialogOpen: boolean,
  setPurchaseQuestionnaireDialogOpen: (open: boolean) => void,
}) => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const analytics = useAnalytics();
  const theme = useTheme();
  const isLowerThanMd = useMediaQuery(theme.breakpoints.down('md'));
  const { isLoading: isLoadingFeatures, ...features } = useFeatures();

  const {
    data: questions,
    isLoading: questionsLoading,
    isError: questionsError,
  } = useGetQuestions({
    formID: config.purchaseQuestionnaireFormId,
    ownerEmail: user?.email ?? '',
    enabled: true,
  });

  const buyBoxStarted = () => {
    analytics.track('Button Clicked', {
      buttonName: 'Buy Box Started',
    });
  };

  const closeDialog = () => {
    setPurchaseQuestionnaireDialogOpen(false);
    navigate('/marketplace');
  };

  const purchaseQuestionnaireExists = (questions ?? []).filter((q) => q.answer !== '').length > 0;

  const view = isLowerThanMd ? (
    <BuyBoxMobile
      questionsError={questionsError}
      questionsLoading={questionsLoading}
      onClick={() => {
        buyBoxStarted();
        setPurchaseQuestionnaireDialogOpen(true);
      }}
      purchaseQuestionnaireExists={purchaseQuestionnaireExists}
    />
  ) : (
    <BuyBoxDesktop
      questionsError={questionsError}
      questionsLoading={questionsLoading}
      onClick={() => {
        buyBoxStarted();
        setPurchaseQuestionnaireDialogOpen(true);
      }}
      purchaseQuestionnaireExists={purchaseQuestionnaireExists}
    />
  );

  return !isLoadingFeatures && !features.isFreemium && (
    <>
      {view}
      {!questionsError && !!questions && (
        <DialogPurchaseQuestionnaire
          questions={questions}
          open={purchaseQuestionnaireDialogOpen}
          closeDialog={closeDialog}
        />
      )}
    </>
  );
};
