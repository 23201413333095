import { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import { useFeatures } from 'lib';
import {
  MdEdit,
} from 'react-icons/md';
import {
  ArrowToggleOpen,
  CollapsibleNavMenu, CollapsibleNavMenuItem,
  CreditCardIcon,
  DashboardIcon,
  Garage, Home,
  InfoTooltip, MenuItem,
  PropertiesIcon, PropertyIcon, PropertySquareMiddleIcon, Report,
  SemiBoldTypography,
  useLabels,
} from 'ui';
import {
  Badge, Button, Collapse,
  IconButton,
  Stack, Typography, useTheme,
} from '@mui/material';

import { useListOwnerProperties } from '../../api/properties';
import { useListOwnerReportsIds } from '../../api/reports';
import { useMissingData } from '../../hooks/useMissingData';
import { Property } from '../../types';

const menuIconSize = 18;
const subMenuIconSize = 16;

const PropertyMenuItem = ({ property, onNavigate = () => {} }: { property: Property, onNavigate?: () => void }) => {
  const theme = useTheme();
  const l = useLabels();
  const { pathname } = useLocation();

  if (property.deleted) {
    return (
      <Stack justifyContent="space-between" direction="row" alignItems="center" mb={3}>
        <Typography
          variant="body2"
          color="text.primary"
          sx={{
            textDecoration: 'line-through',
          }}
        >
          {property.displayName}
        </Typography>
        <InfoTooltip
          isLight
          isOutlined
          title={l.propertyNoLongerMonitored}
          arrow
        />
      </Stack>
    );
  }

  return (
    <Stack key={`nav-${property.id}`} justifyContent="space-between" direction="row" alignItems="center" mb={3}>
      <Link
        to={`/properties/${property.id}`}
        style={{
          textDecoration: 'none',
          display: 'flex',
          alignItems: 'center',
        }}
        onClick={onNavigate}
      >
        <Typography
          variant="body2"
          color="text.primary"
          sx={{
            color: pathname === `/properties/${property.id}`
              ? theme.palette.primary.dark : theme.palette.text.primary,
            textDecoration: property.deleted ? 'line-through' : 'none',
          }}
        >
          {property.displayName}
        </Typography>
      </Link>
      <Link
        to={`/onboarding/${property.id}`}
        style={{ textDecoration: 'none', display: 'flex', alignItems: 'center' }}
        onClick={onNavigate}
      >
        <IconButton aria-label="edit" size="small">
          <MdEdit />
        </IconButton>
      </Link>
    </Stack>
  );
};

export const DrawerMenuItems = ({ onNavigate = () => { } }: { onNavigate?: () => void }) => {
  const { data: ownerReports, isLoading: ownerReportsLoading } = useListOwnerReportsIds();

  const l = useLabels();
  const theme = useTheme();
  const navigate = useNavigate();
  const { data: ownerProperties } = useListOwnerProperties();
  const properties = ownerProperties?.properties ?? [];
  const deletedProperties = ownerProperties?.deletedProperties ?? [];
  const totalPropertyCount = properties.length + deletedProperties.length;
  const { missingData } = useMissingData(properties);
  const { pathname } = useLocation();
  const [propertiesOpen, setPropertiesOpen] = useState(false);
  const handleNavigate = (path: string) => {
    onNavigate!();
    navigate(path);
  };

  const hasReport = ownerReports ? ownerReports?.length > 0 : false;

  return (
    <Stack>
      <MenuItem
        isActive={pathname === '/'}
        label={l['menu-dashboard']}
        onClick={() => handleNavigate('/')}
        icon={<Home />}
      />
      {totalPropertyCount > 1 && (
        <Stack>
          <Stack
            direction="row"
            justifyContent="space-between"
            component={Button}
            onClick={() => setPropertiesOpen((open) => !open)}
            gap={3}
            py={3}
            px={4}
            mb={0}
            fullWidth
            sx={{
              color: theme.palette.text.primary,
            }}
          >
            <Stack
              direction="row"
              justifyContent="flex-start"
              gap={3}
              p={0}
              m={0}
            >
              <PropertiesIcon />
              <SemiBoldTypography variant="body1" sx={{ textTransform: 'none' }}>
                {l['menu-properties']}
              </SemiBoldTypography>
            </Stack>
            <ArrowToggleOpen size={22} className={propertiesOpen ? 'open' : ''} />
          </Stack>
          <Stack
            pl={10}
            pr={4}
            pt={propertiesOpen ? 1 : 0}
          >
            <Collapse in={propertiesOpen}>
              {properties?.map((property) => (
                <PropertyMenuItem property={property} onNavigate={onNavigate} key={property.id} />
              ))}
              {deletedProperties?.map((property) => (
                <PropertyMenuItem property={property} onNavigate={onNavigate} key={property.id} />
              ))}
            </Collapse>
          </Stack>
        </Stack>
      )}
      <MenuItem
        label={l['menu-transactions']}
        isActive={pathname === '/transactions'}
        onClick={() => handleNavigate('/transactions')}
        icon={(
          <Badge badgeContent="" color="error" variant="dot" invisible={!missingData.expenses.isMissing}>
            <CreditCardIcon />
          </Badge>
        )}
      />
      <MenuItem
        label={l['menu-update-properties']}
        isActive={pathname?.startsWith('/onboarding')}
        onClick={() => handleNavigate('/onboarding')}
        icon={<Garage />}
      />
      {!ownerReportsLoading && hasReport && (
        <MenuItem
          dataTestId="left-reports-btn"
          label={l['menu-reports']}
          isActive={pathname?.startsWith('/reports')}
          onClick={() => handleNavigate('/reports')}
          icon={<Report />}
        />
      )}
    </Stack>
  );
};

export const ProductMenuItems = ({ onNavigate = () => { } }: { onNavigate?: () => void }) => {
  const { data: ownerReports, isLoading: ownerReportsLoading } = useListOwnerReportsIds();
  const { isLoading: isLoadingFeatures, ...features } = useFeatures();

  const l = useLabels();
  const theme = useTheme();
  const navigate = useNavigate();
  const { data: ownerProperties } = useListOwnerProperties();
  const properties = ownerProperties?.properties ?? [];
  const deletedProperties = ownerProperties?.deletedProperties ?? [];
  const totalPropertyCount = properties.length + deletedProperties.length;
  const { pathname } = useLocation();
  const handleNavigate = (path: string) => {
    onNavigate!();
    navigate(path);
  };

  const hasReport = ownerReports ? ownerReports?.length > 0 : false;

  const categoryActive = [
    pathname === '/',
    pathname?.startsWith('/properties'),
    pathname?.startsWith('/onboarding'),
    pathname?.startsWith('/transactions'),
    pathname?.startsWith('/reports'),
  ].includes(true);

  if (ownerReportsLoading || isLoadingFeatures) {
    return null;
  }

  return features.isOwnerDashboardEnabled && (
    <CollapsibleNavMenu
      initialOpen={categoryActive}
      icon={<DashboardIcon height={menuIconSize} width={menuIconSize} />}
      label={l['menu-dashboard']}
      isActive={categoryActive}
    >
      {totalPropertyCount > 1 ? (
        <>
          <CollapsibleNavMenuItem
            icon={<Garage height={subMenuIconSize} width={subMenuIconSize} />}
            text={l['menu-portfolio']}
            onNavigate={() => handleNavigate('/')}
            isActive={pathname === '/'}
          />
          <CollapsibleNavMenu
            initialOpen={pathname?.startsWith('/properties')}
            icon={<PropertySquareMiddleIcon height={menuIconSize} width={menuIconSize} />}
            label={l['menu-properties']}
            itemPaddingLeft={6}
          >
            {properties.map((property) => (
              <CollapsibleNavMenuItem
                key={property.id}
                text={property.displayName}
                onNavigate={() => handleNavigate(`/properties/${property.id}`)}
                isActive={pathname === `/properties/${property.id}`}
                action={(
                  <IconButton
                    aria-label="edit"
                    size="small"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();

                      navigate(`/onboarding/${property.id}`);
                    }}
                  >
                    <MdEdit color={pathname === `/properties/${property.id}` ? theme.palette.primary.contrastText : undefined} />
                  </IconButton>
                )}
              />
            ))}
            {deletedProperties.map((property) => (
              <CollapsibleNavMenuItem
                key={property.id}
                icon={<PropertyIcon size={subMenuIconSize} />}
                text={property.displayName}
                action={(
                  <InfoTooltip
                    arrow
                    isLight
                    isOutlined
                    isSmall
                    title={l.propertyNoLongerMonitored}
                  />
                )}
                isActive={pathname === `/properties/${property.id}`}
                textSx={{ textDecoration: 'line-through' }}
              />
            ))}
          </CollapsibleNavMenu>
        </>
      ) : (
        <CollapsibleNavMenuItem
          icon={<Garage height={subMenuIconSize} width={subMenuIconSize} />}
          text={l['menu-portfolio']}
          onNavigate={() => handleNavigate('/')}
          isActive={pathname === '/'}
        />
      )}
      <CollapsibleNavMenuItem
        icon={<CreditCardIcon height={subMenuIconSize} width={subMenuIconSize} />}
        text={l['menu-transactions']}
        onNavigate={() => handleNavigate('/transactions')}
        isActive={pathname?.startsWith('/transactions')}
      />
      <CollapsibleNavMenuItem
        icon={<Garage height={subMenuIconSize} width={subMenuIconSize} />}
        text={l['menu-update-properties']}
        onNavigate={() => handleNavigate('/onboarding')}
        isActive={pathname?.startsWith('/onboarding')}
      />
      {hasReport && (
        <CollapsibleNavMenuItem
          icon={<Report height={subMenuIconSize} width={subMenuIconSize} />}
          text={l['menu-reports']}
          onNavigate={() => handleNavigate('/reports')}
          isActive={pathname?.startsWith('/reports')}
        />
      )}
    </CollapsibleNavMenu>
  );
};
