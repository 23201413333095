import { useAnalytics } from 'lib';
import { MdClose } from 'react-icons/md';
import {
  Button,
  Dialog,
  DialogContent,
  IconButton,
  Stack,
  Typography, useMediaQuery, useTheme,
} from '@mui/material';

import { ConfettiCircle } from '../icons';
import { useLabels } from '../lib/translations';
import { Spacer } from '../spacer/Spacer';
import { BoldTypography } from '../typography/BoldTypography';

export const SuccessDialogConfetti = ({
  open,
  onClose,
  message,
  dialogName,
}: {
  open: boolean,
  onClose: () => void,
  message: string,
  dialogName: string,
}) => {
  const l = useLabels();
  const theme = useTheme();
  const isLowerThanMd = useMediaQuery(theme.breakpoints.down('md'));
  const analytics = useAnalytics();

  const handleClickClose = (e: any, reason: 'backdropClick' | 'escapeKeyDown' | 'buttonClick') => {
    if (reason === 'backdropClick') return;

    analytics.track('Button Clicked', {
      buttonName: `Close - ${dialogName} Dialog`,
      message,
    });

    onClose();
  };

  return (
    <Dialog open={open} onClose={handleClickClose} fullScreen={isLowerThanMd} disableEscapeKeyDown>
      <Stack direction="row" justifyContent="flex-end" alignItems="center" width="100%" p={3}>
        <IconButton onClick={() => handleClickClose({}, 'buttonClick')}>
          <MdClose />
        </IconButton>
      </Stack>
      <DialogContent sx={{
        px: `${theme.spacing(16)} !important`,
        pb: `${theme.spacing(4)} !important`,
        pt: '0 !important',
      }}
      >
        <Stack direction="column" alignItems="center" pb={4}>
          <ConfettiCircle />
          <Spacer spacing={2} />
          <BoldTypography>
            {l.thankYou}
          </BoldTypography>
          <Spacer spacing={0.5} />
          <Typography variant="body1">
            {message}
          </Typography>
          <Spacer spacing={2} />
          <Button
            size="medium"
            variant="outlined"
            onClick={() => handleClickClose({}, 'buttonClick')}
          >
            {l.done}
          </Button>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};
