import { useListStates } from 'lib';
import { BoldTypography, InfoTooltip, useLabels } from 'ui';
import { Stack, Typography, useTheme } from '@mui/material';

import { SuggestedPartner, WorkingCounty } from '../../api/suggested-partners/types';

export const WorkingCounties = ({
  id,
  workingCounties,
  isBold = false,
  colorSecondary = false,
  variant = 'body1',
}: {
  id: string,
  workingCounties: WorkingCounty[],
  isBold?: boolean,
  colorSecondary?: boolean,
  variant?: 'body1' | 'body2'
}) => {
  const l = useLabels();
  const theme = useTheme();
  const TypographyComponent = isBold ? BoldTypography : Typography;
  const { data: states, isLoading: isLoadingStates } = useListStates();

  const formatWorkingArea = (area: { state: string, county: string }) => `${area.county}, ${states?.get(area.state)?.name ?? ''}`;

  const stateToWorkingAreas: Record<string, SuggestedPartner['workingCounties']> = workingCounties.reduce((acc, area) => {
    const { state, county } = area;

    if (!acc[state]) {
      acc[state] = [];
    }

    acc[state].push({ state, county });

    return acc;
  }, {} as Record<string, SuggestedPartner['workingCounties']>);

  const stateWithMaxAreas = Object.keys(stateToWorkingAreas).sort(
    (a, b) => stateToWorkingAreas[b].length - stateToWorkingAreas[a].length,
  )[0];

  if (isLoadingStates) return null;

  if (!stateWithMaxAreas) {
    return (
      <TypographyComponent variant={variant} color={colorSecondary ? 'secondary' : undefined}>
        {l.na}
      </TypographyComponent>
    );
  }

  const selectedAreas = stateToWorkingAreas[stateWithMaxAreas];

  if (selectedAreas.length === 1) {
    return (
      <TypographyComponent variant={variant} color={colorSecondary ? 'secondary' : undefined}>
        {formatWorkingArea(selectedAreas[0])}
      </TypographyComponent>
    );
  }

  const fullStateName = states?.get(stateWithMaxAreas)?.name ?? '';

  return (
    <Stack direction="row" alignItems="center" gap={2}>
      <TypographyComponent variant={variant} color={colorSecondary ? 'secondary' : undefined}>
        {fullStateName ? `${fullStateName}, USA` : 'USA'}
        {` (${selectedAreas.length})`}
      </TypographyComponent>
      <InfoTooltip
        arrow
        isSmall
        isLight
        isOutlined
        title={(
          <Stack p={1} gap={2}>
            <TypographyComponent variant={variant}>
              {l['partners.card.workingCounties']}
            </TypographyComponent>
            <ul style={{ margin: 0, paddingLeft: theme.spacing(3) }}>
              {selectedAreas.map((area) => (
                <li key={`${id}::${area.county}::${area.state}`}>
                  <Typography variant="body2">{formatWorkingArea(area)}</Typography>
                </li>
              ))}
            </ul>
          </Stack>
        )}
      />
    </Stack>
  );
};
