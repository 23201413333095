import { GraphQLResult } from '@aws-amplify/api-graphql';
import { useQuery } from '@tanstack/react-query';

import { useAuth } from '../../hooks/useAuth';
import { QueryKey } from '../../types/enums';
import { queryGraphQL } from '../amplify';
import { ListStatesQuery } from '../API';
import { listStates } from '../graphql/queries';

type State = {
  abbreviation: string,
  name: string,
  insuranceFactor: number,
};

export const useListStates = () => {
  const { getAccessTokenSilently } = useAuth();

  return useQuery([QueryKey.STATES], async (): Promise<Map<string, State>> => {
    const token = await getAccessTokenSilently();

    const res = await queryGraphQL({
      query: listStates,
      authToken: token,
    }) as GraphQLResult<ListStatesQuery>;

    const statsInsuranceFactor = new Map<string, State>();

    res.data?.listStates?.items.forEach((s) => {
      if (!s) return;
      statsInsuranceFactor.set(s.id, {
        abbreviation: s.id,
        name: s.name,
        insuranceFactor: s.insuranceFactor ?? 0,
      });
    });

    return statsInsuranceFactor;
  }, { staleTime: Infinity });
};
