import {
  GrowthPolicy,
  useFeatures,
} from 'lib';
import { FallbackSpinner, useLabels } from 'ui';
import { signal } from '@preact/signals-react';

import { Header, SortOption } from './Header';
import { SuggestedPartners } from './SuggestedPartners';
import { SuggestedPartner } from '../../api/suggested-partners/types';

const searchSignal = signal('');
const sortOptionSignal = signal<SortOption>(SortOption.TOP_PICKS);

export const AgentsHeader = ({ suggestedPartners }: { suggestedPartners: SuggestedPartner[] }) => {
  const { isLoading, ...features } = useFeatures();

  return (
    <Header
      enabled={!isLoading && features.isGrowthAgentPartnersEnabled}
      suggestedPartners={suggestedPartners}
      search={searchSignal.value}
      setSearch={(search: string) => { searchSignal.value = search; }}
      sortOption={sortOptionSignal.value}
      setSortOption={(sortOption: SortOption) => { sortOptionSignal.value = sortOption; }}
      sortOptions={[
        SortOption.TOP_PICKS,
        SortOption.ACTIVITY_LEVEL,
        SortOption.YEARS_OF_EXPERIENCE,
        SortOption.A_TO_Z,
        SortOption.Z_TO_A,
      ]}
    />
  );
};

export const Agents = ({
  suggestedPartners,
  growthPolicy,
}: {
  suggestedPartners: SuggestedPartner[],
  growthPolicy: GrowthPolicy | null,
}) => {
  const l = useLabels();
  const { isLoading, ...features } = useFeatures();

  if (isLoading) return <FallbackSpinner />;

  return (
    <SuggestedPartners
      upSellIfDisabled
      disabledTitle={l['partners.agents.disabled.title']}
      disabledSubtitle={l['partners.agents.disabled.subtitle']}
      disabledActionText={l['partners.agents.disabled.button']}
      suggestedPartners={suggestedPartners}
      growthPolicy={growthPolicy}
      initialSearch={searchSignal.value}
      sortOption={sortOptionSignal.value}
      enabled={features.isGrowthAgentPartnersEnabled}
    />
  );
};
