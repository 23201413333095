import { useEffect, useState } from 'react';

import dayjs from 'dayjs';
import {
  OwnerLeadsWorkflowStatus, OwnerLeadWorkflowItem, useAnalytics, useFeatures,
} from 'lib';
import { toast } from 'react-toastify';
import {
  BoldTypography, capitalize, FallbackSpinner, formatPotentialTodayDate, ListBoard, RowGroup, RowGroupConfig, useLabels,
} from 'ui';
import { Stack, Typography } from '@mui/material';
import { signal } from '@preact/signals-react';
import { useQueryClient } from '@tanstack/react-query';
import { ColumnDef } from '@tanstack/react-table';

import { useListOwnerLeads, useMutateOwnerLead } from '../../../api/workflows';
import { QueryKey } from '../../../types/enums';
import { Contact } from '../components/Contact';
import { EmptyDash } from '../components/EmptyDash';
import { StatusChanger } from '../components/StatusChanger';
import { CardDetail } from '../kanban/ColCard';
import { sortContacts } from '../utils';
import { useOwnerLeadStatuses } from '../WorkflowStatus';

const rowGroupsSignal = signal<RowGroup<OwnerLeadWorkflowItem>[]>([]);

export const OwnerLeadsList = ({ orderedStatuses }: { orderedStatuses: OwnerLeadsWorkflowStatus[] }) => {
  const l = useLabels();
  const { getStatus } = useOwnerLeadStatuses();
  const { data, isLoading } = useListOwnerLeads();
  const { isLoading: isLoadingFeatures, ...features } = useFeatures();

  useEffect(() => {
    const columns: (ColumnDef<OwnerLeadWorkflowItem, string> | null)[] = [
      !isLoadingFeatures && !features.isLeadsThirdPartyIntegrationEnabled ? {
        header: l.status,
        // eslint-disable-next-line react/no-unstable-nested-components
        cell: (info) => (
          <OwnerLeadStatusChanger item={info.row.original} />
        ),
        meta: { sticky: true },
      } : null,
      {
        header: l.name,
        id: l.name,
        accessorKey: 'lead.email',
        sortingFn: (a, b) => sortContacts(a.original.lead, b.original.lead),
        // eslint-disable-next-line react/no-unstable-nested-components
        cell: (info) => <Contact contact={info.row.original.lead} />,
        meta: { sticky: true },
      },
      {
        header: l.phone,
        // eslint-disable-next-line react/no-unstable-nested-components
        cell: (info) => info.row.original.lead?.phoneNumber || <EmptyDash />,
        meta: { shownAsText: true },
      },
      {
        header: l.partner,
        id: l.partner,
        accessorKey: 'ownerLead.partner',
        sortingFn: (a, b) => sortContacts(a.original.ownerLead?.partner, b.original.ownerLead?.partner),
        // eslint-disable-next-line react/no-unstable-nested-components
        cell: (info) => (
          info.row.original.ownerLead?.partner
            ? <Contact contact={info.row.original.ownerLead?.partner} />
            : <EmptyDash />
        ),
      },
      {
        header: l.address,
        // eslint-disable-next-line react/no-unstable-nested-components
        cell: (info) => ((info.row.original.addresses?.length ?? 0) > 0 ? (
          <CardDetail
            label=""
            content={`
              ${info.row.original.addresses?.length ?? 0}
              ${info.row.original.addresses?.length === 1 ? l.property : l.properties}`}
            tooltipName="Owner Lead Addresses"
            tooltipContent={(
              <Stack gap={2} p={1}>
                <BoldTypography variant="body1">{l.properties}</BoldTypography>
                {info.row.original.addresses?.map((address) => address.trim() && (
                  <Stack key={address} direction="row" gap={1} alignItems="center">
                    <Typography variant="body2">{address}</Typography>
                  </Stack>
                ))}
              </Stack>
            )}
          />
        ) : <EmptyDash />),
      },
      {
        header: l['pm-dashboard.leads.requestRentalAnalysis'],
        id: l['pm-dashboard.leads.requestRentalAnalysis'],
        minSize: 160,
        enableSorting: true,
        accessorKey: 'requestRentalAnalysis',
        cell: (info) => (info.row.original.requestRentalAnalysis ? capitalize(l.yes) : capitalize(l.no)),
        meta: { shownAsText: true },
      },
      {
        id: l.updated,
        header: l.updated,
        accessorKey: 'updatedTime',
        // eslint-disable-next-line react/no-unstable-nested-components
        cell: (info) => formatPotentialTodayDate(info.row.original.updatedTime),
        meta: { shownAsText: true },
        sortingFn: (a, b) => dayjs(a.original.updatedTime).diff(dayjs(b.original.updatedTime)),
      },
    ];

    const leads = data?.pages.flatMap((page) => page.leads) ?? [];

    const groups: RowGroupConfig<OwnerLeadWorkflowItem>[] = [];

    orderedStatuses.forEach((status) => {
      const statusConfig = getStatus(status);

      groups.push({
        rows: leads?.filter((lead) => lead.status === status) ?? [],
        columnDefs: columns,
        id: status,
        color: statusConfig.color,
        icon: statusConfig.smallIcon,
        title: statusConfig.displayValue,
        tooltip: statusConfig.tooltip,
      });
    });

    rowGroupsSignal.value = groups;
  }, [data, features, isLoadingFeatures]);

  if (isLoading) return <FallbackSpinner />;

  return (
    <ListBoard rowGroups={rowGroupsSignal.value} columnSort={[{ id: l.updated, desc: true }]} />
  );
};

const OwnerLeadStatusChanger = ({ item }: { item: OwnerLeadWorkflowItem }) => {
  const l = useLabels();
  const analytics = useAnalytics();
  const { statuses } = useOwnerLeadStatuses();
  const queryClient = useQueryClient();

  const [invalidatingQueries, setInvalidatingQueries] = useState(false);
  const { mutateAsync: updateStatus, isLoading: isUpdatingStatus } = useMutateOwnerLead();

  const handleStatusChange = async (status: OwnerLeadsWorkflowStatus) => {
    analytics.track('Selector Changed', {
      selectorName: 'Owner Lead Status',
      value: status,
    });

    try {
      await updateStatus({
        id: item.id,
        status,
      });
      setInvalidatingQueries(true);
      await queryClient.invalidateQueries([QueryKey.OWNER_LEADS]);
      setInvalidatingQueries(false);
    } catch (err) {
      console.error(err);

      toast.error(l['error.unknownError']);
    }
  };

  const isLoading = invalidatingQueries || isUpdatingStatus;

  return (
    <StatusChanger
      id={item.id}
      status={item.status}
      statuses={statuses}
      changeStatus={handleStatusChange}
      isLoading={isLoading}
    />
  );
};
