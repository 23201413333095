import { ReactElement, ReactNode, useEffect } from 'react';

import { config, useAppSettings, useAuth } from 'lib';
import { FallbackSpinner } from 'ui';
import { AuthProvider as DescopeAuthProvider, useSession } from '@descope/react-sdk';

import { DescopeLogin } from './DescopeLogin';

type Props = {
  children: ReactNode
};

const DescopeAuthGuard = ({ children }: Props) => {
  const { isAuthenticated, isSessionLoading } = useSession();
  const { data: appSettings, isLoading } = useAppSettings();
  const { user, logout } = useAuth();

  // If the user authenticated using in a different tenant, log them out
  useEffect(() => {
    if (isAuthenticated && user && user?.pm !== appSettings?.tenant) {
      logout();
    }
  }, [isAuthenticated, user, appSettings]);

  if (!appSettings || isLoading || isSessionLoading) return <FallbackSpinner />;

  if (!isAuthenticated) {
    return (
      <DescopeLogin />
    );
  }

  // this is to avoid extra calls to /me from children components that use useAuth
  if (!user) return null;

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};

export const AuthProvider = ({ children }: Props): ReactElement => {
  const { data: appSettings, isLoading } = useAppSettings();

  if (!appSettings || isLoading) return <FallbackSpinner />;

  return (
    <DescopeAuthProvider projectId={config.descopeProjectId} baseUrl={config.descopeBaseURL}>
      <DescopeAuthGuard>
        {children}
      </DescopeAuthGuard>
    </DescopeAuthProvider>
  );
};
