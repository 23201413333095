/* eslint-disable react/no-unstable-nested-components */
import {
  useEffect,
  useMemo,
  useState,
} from 'react';

import dayjs from 'dayjs';
import {
  OwnerRiskMitigation,
  OwnerRiskSeverity, OwnerRiskStatus, ResolvedReason, useAnalytics, useGetUsers,
} from 'lib';
import { toast } from 'react-toastify';
import {
  AdminView,
  defaultTableControlsState,
  EmptyFullPageContainer,
  formatNumberToCurrency,
  formatPotentialTodayDate,
  FullScreenBlur,
  RetentionPolicyIcon,
  SpinnerWithLogo, useEnableScroll,
  useLabels,
} from 'ui';
import {
  Alert,
  Button,
  ButtonGroup,
  Grid, Stack,
  useTheme,
} from '@mui/material';
import { signal } from '@preact/signals-react';
import { useQueryClient } from '@tanstack/react-query';
import {
  ColumnDef,
} from '@tanstack/react-table';

import { BlanketScore } from './components/BlanketScore';
import { OwnerActions } from './components/columns/OwnerActions';
import { OwnerCell } from './components/columns/OwnerCell';
import { Priority } from './components/columns/Priority';
import { RiskScore } from './components/columns/RiskScore';
import { ActDialog } from './components/dialogs/ActDialog';
import { GenerateEmailDialog } from './components/dialogs/GenerateEmailDialog';
import { ResolveDialog } from './components/dialogs/ResolveDialog';
import { ExpandedRow } from './components/ExpandedRow';
import { RetentionPolicy } from './components/RetentionPolicy';
import { SeverityGauge } from './components/SeverityGauge';
import { ValueAndProperties } from './components/ValueAndProperties';
import {
  currentRetainTab, RetainTab, retentionPolicyDialogState, showOwnerRiskMovedToInProgressToast,
} from './state';
import {
  useGetOwnerRisk,
  useGetOwnerRisksByPM,
  useMutateOwnerRiskRecommendation,
  useUpdateOwnerRisk,
} from '../../api/owner-risk';
import { BasicOwnerRisk } from '../../api/properties/types';
import { useGetRetentionPolicy } from '../../api/retention-policy';
import { QueryKey } from '../../types/enums';

const Widgets = () => (
  <Grid container spacing={3}>
    <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
      <BlanketScore />
    </Grid>
    <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
      <SeverityGauge />
    </Grid>
    <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
      <ValueAndProperties />
    </Grid>
    <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
      <RetentionPolicy />
    </Grid>
  </Grid>
);

export const Retain = () => {
  const l = useLabels();
  const theme = useTheme();
  const analytics = useAnalytics();
  const [resolveDialogOpen, setResolveDialogOpen] = useState(false);
  const [actDialogOpen, setActDialogOpen] = useState(false);
  const [generateEmailDialogOpen, setGenerateEmailDialogOpen] = useState(false);
  const { data: retentionPolicy, isLoading: isLoadingRetentionPolicy } = useGetRetentionPolicy();
  const queryClient = useQueryClient();
  const isRetentionPolicyInPlace = !!retentionPolicy && !isLoadingRetentionPolicy;

  useEnableScroll(isRetentionPolicyInPlace && !retentionPolicyDialogState.value.open);

  const {
    mutateAsync: createRiskRecommendation,
    isLoading: isCreatingRiskRecommendation,
    isError: errorCreatingRiskRecommendation,
  } = useMutateOwnerRiskRecommendation();
  const { mutateAsync: updateOwnerRisk, isLoading: isUpdatingOwnerRisk } = useUpdateOwnerRisk();
  const { data, isLoading, hasNextPage } = useGetOwnerRisksByPM();
  const { data: owners, isLoading: isLoadingOwners } = useGetUsers(['owner']);

  const forceActButtonLoading = isCreatingRiskRecommendation || isUpdatingOwnerRisk;

  const setOwnerRiskID = (ownerRisk: BasicOwnerRisk) => {
    selectedOwnerRisk.value = {
      id: ownerRisk.id,
      hasExistingRecommendation: !!ownerRisk.recommendation,
    };
  };

  const ownerToVerified: Record<string, boolean> = useMemo(() => {
    const ownerIdToActivated: Record<string, boolean> = {};

    owners?.forEach((owner) => {
      ownerIdToActivated[owner.id] = owner.isActivated;
    });

    return ownerIdToActivated;
  }, [owners, isLoadingOwners]);

  const ownerRisks = useMemo(() => {
    const allOwnerRisks = data?.pages.flatMap((page) => page.ownerRisks) ?? [];
    const risks = allOwnerRisks.filter((ownerRisk) => !ownerRisk.isNoRisk);

    switch (currentRetainTab.value) {
      case RetainTab.NEW:
        return risks.filter((ownerRisk) => ownerRisk.status === OwnerRiskStatus.NEW);
      case RetainTab.IN_PROGRESS:
        return risks.filter((ownerRisk) => ownerRisk.status === OwnerRiskStatus.IN_PROGRESS);
      case RetainTab.RESOLVED:
        return risks.filter((ownerRisk) => ownerRisk.status === OwnerRiskStatus.RESOLVED);
      case RetainTab.NO_RISK:
        return allOwnerRisks.filter((ownerRisk) => ownerRisk.isNoRisk);
      default:
        return [];
    }
  }, [data, currentRetainTab.value]);

  const ownerRiskCounts: Record<RetainTab, number> = useMemo(() => {
    const allOwnerRisks = data?.pages.flatMap((page) => page.ownerRisks) ?? [];
    const noRisk = allOwnerRisks.filter((ownerRisk) => ownerRisk.isNoRisk);
    const risks = allOwnerRisks.filter((ownerRisk) => !ownerRisk.isNoRisk);

    return {
      new: risks.filter((ownerRisk) => ownerRisk.status === OwnerRiskStatus.NEW).length,
      inProgress: risks.filter((ownerRisk) => ownerRisk.status === OwnerRiskStatus.IN_PROGRESS).length,
      resolved: risks.filter((ownerRisk) => ownerRisk.status === OwnerRiskStatus.RESOLVED).length,
      noRisk: noRisk.length,
    };
  }, [data]);

  const columns = useMemo(() => {
    type Column = ColumnDef<BasicOwnerRisk, string> | null;

    const riskScoreColumn: Column = {
      header: l['retention.table.riskScore'],
      enableSorting: true,
      minSize: 170,
      accessorFn: (row) => `${row.risk ?? 0}`,
      sortingFn: (a, b) => (a.original.risk ?? 0) - (b.original.risk ?? 0),
      cell: (info) => <RiskScore ownerRisk={info.row.original} expandRow={() => info.row.toggleExpanded(true)} />,
    };
    const priorityColumn: Column = {
      header: l['retention.table.priority'],
      enableSorting: true,
      accessorFn: (row) => row.severity,
      size: 100,
      sortingFn: (a, b) => {
        const priorities = [OwnerRiskSeverity.LOW, OwnerRiskSeverity.MEDIUM, OwnerRiskSeverity.HIGH];

        const aPriority = priorities.indexOf(a.original.severity);
        const bPriority = priorities.indexOf(b.original.severity);

        if (aPriority === bPriority) {
          return 0;
        }

        return aPriority < bPriority ? -1 : 1;
      },
      cell: (info) => <Priority ownerRisk={info.row.original} />,
    };

    const necessaryColumns: Column[] = [
      {
        header: l['retention.table.owner'],
        enableSorting: true,
        accessorFn: (row) => `${row.ownerDetails.firstName ?? ''} ${row.ownerDetails.lastName ?? ''}`,
        cell: (info) => (
          <OwnerCell
            ownerRisk={info.row.original}
            activated={ownerToVerified[info.row.original.ownerID] ?? false}
            isLoading={isLoadingOwners}
          />
        ),
      },
      {
        header: l['retention.table.clientValue'],
        enableSorting: true,
        minSize: 160,
        size: 160,
        sortingFn: (a, b) => (a.original.clientValue ?? 0) - (b.original.clientValue ?? 0),
        accessorFn: (row) => formatNumberToCurrency(row.clientValue, 0),
        cell: (info) => formatNumberToCurrency(info.row.original.clientValue, 0),
        meta: { shownAsText: true },
      },
    ];

    const updatedColumn: Column = {
      header: l['retention.table.updated'],
      enableSorting: true,
      accessorFn: (row) => dayjs(row.updatedTime).format('MM/DD/YYYY, hh:mm'),
      sortingFn: (a, b) => dayjs(a.original.updatedTime).diff(dayjs(b.original.updatedTime)),
      cell: (info) => formatPotentialTodayDate(info.row.original.updatedTime),
      meta: { shownAsText: true },
    };
    const resolvedDateColumn: Column = {
      header: l['retention.resolvedDate'],
      enableSorting: true,
      minSize: 180,
      accessorFn: (row) => dayjs(row.resolvedDate).format('MM/DD/YYYY, hh:mm'),
      cell: (info) => info.row.original.resolvedDate && formatPotentialTodayDate(info.row.original.resolvedDate),
      meta: { shownAsText: true },
    };
    const resolvedReasonColumn: Column = {
      header: l['retention.resolvedReason'],
      minSize: 220,
      cell: (info) => info.row.original.resolvedReason && ({
        [ResolvedReason.OWNER_WANTS_TO_STAY]: l['retention.resolve.reason.ownerWantsToStay'],
        [ResolvedReason.OWNER_WANTS_TO_SELL]: l['retention.resolve.reason.ownerWantsToSell'],
        [ResolvedReason.OWNER_WANTS_TO_LEAVE]: l['retention.resolve.reason.ownerWantsToLeave'],
        [ResolvedReason.OTHER]: l['retention.resolve.reason.other'],
      }[info.row.original.resolvedReason]),
      meta: { shownAsText: true },
    };
    const ownerActionsColumn: Column = {
      header: ' ',
      cell: (info) => (
        <OwnerActions
          ownerRisk={info.row.original}
          forceActButtonLoading={forceActButtonLoading}
          onResolveClick={(ownerRisk) => {
            setOwnerRiskID(ownerRisk);
            setResolveDialogOpen(true);
          }}
          onActClick={async (ownerRisk) => {
            setOwnerRiskID(ownerRisk);
            setActDialogOpen(true);

            if (!ownerRisk.recommendation) {
              try {
                await Promise.all([
                  createRiskRecommendation({
                    ownerRiskID: ownerRisk.id,
                    regenerate: false,
                  }),
                  updateOwnerRisk({ ownerRiskID: ownerRisk.id, status: OwnerRiskStatus.IN_PROGRESS }),
                ]);
                await Promise.all([
                  queryClient.invalidateQueries([QueryKey.OWNER_RISK, ownerRisk.id]),
                  queryClient.invalidateQueries([QueryKey.OWNER_RISKS_BY_PM]),
                ]);

                showOwnerRiskMovedToInProgressToast.value = true;
              } catch (e) {
                console.error(e);
                toast.error(l['error.unknownError']);
              }
            }
          }}
        />
      ),
    };

    switch (currentRetainTab.value) {
      case RetainTab.NO_RISK:
        return [
          ...necessaryColumns,
          updatedColumn,
          ownerActionsColumn,
        ];
      case RetainTab.NEW:
        return [
          ...necessaryColumns,
          riskScoreColumn,
          priorityColumn,
          updatedColumn,
          ownerActionsColumn,
        ];
      case RetainTab.IN_PROGRESS:
        return [
          ...necessaryColumns,
          riskScoreColumn,
          priorityColumn,
          updatedColumn,
          ownerActionsColumn,
        ];
      case RetainTab.RESOLVED:
        return [
          ...necessaryColumns,
          riskScoreColumn,
          priorityColumn,
          resolvedDateColumn,
          resolvedReasonColumn,
          ownerActionsColumn,
        ];
      default:
        return [];
    }
  }, [ownerToVerified, isLoadingOwners, currentRetainTab.value]);

  const filterOwnerRisks = (rows: BasicOwnerRisk[], search: string) => rows.filter((ownerRisk) => {
    const email = ownerRisk.ownerID.split('::')[1];
    const searchable = `${ownerRisk.ownerDetails.firstName ?? ''} ${ownerRisk.ownerDetails.lastName ?? ''} ${email}`;

    return searchable.toLowerCase().includes(search.toLowerCase());
  });

  const setCurrentOwnerRiskTab = (tab: RetainTab) => {
    analytics.track('Selector Changed', {
      selectorName: 'Retention Table - Status',
      value: tab,
      count: ownerRiskCounts[tab],
    });

    if (currentRetainTab.value === tab) return;

    currentRetainTab.value = tab;
    defaultTableControlsState.value.setSorting(getSortByTab(tab));
    defaultTableControlsState.value.resetExpandedRows();
  };

  const getSortByTab = (tab: RetainTab) => {
    if (tab === RetainTab.NO_RISK) {
      return [{ id: l['retention.table.clientValue'], desc: true }, { id: l['retention.table.updated'], desc: true }];
    }

    return [
      { id: l['retention.table.priority'], desc: true },
      { id: l['retention.table.riskScore'], desc: true },
      { id: l['retention.table.clientValue'], desc: true },
    ];
  };

  if (isLoading || hasNextPage || isLoadingRetentionPolicy) {
    return (
      <EmptyFullPageContainer>
        <SpinnerWithLogo />
      </EmptyFullPageContainer>
    );
  }

  return (
    <Stack position="relative" height="100%" pb={20}>
      <FullScreenBlur
        shown={!isRetentionPolicyInPlace}
      >
        <Alert
          variant="filled"
          severity="error"
          icon={<RetentionPolicyIcon height={18} width={18} />}
          sx={{ color: theme.palette.primary.contrastText, alignItems: 'center' }}
          action={(
            <Button
              variant="contained"
              size="small"
              color="error"
              onClick={() => retentionPolicyDialogState.value.openDialog()}
            >
              {l['retention.createPolicy']}
            </Button>
          )}
        >
          {l['retention.pleaseSetYourPreferences']}
        </Alert>
      </FullScreenBlur>
      <AdminView
        title={l.retain}
        getRowCanExpand={() => true}
        renderSubComponent={(info) => <ExpandedRow row={info.row} />}
        searchPlaceholder={l['retention.searchOwners']}
        data={ownerRisks}
        columns={columns}
        initialState={getSortByTab(currentRetainTab.value)}
        filter={filterOwnerRisks}
        emptyStateText={
          currentRetainTab.value === RetainTab.NEW
            && ownerRiskCounts.resolved
            + ownerRiskCounts.inProgress > 0
            ? l['retention.goodNewsNoPotentialRisks']
            : l['retention.noPotentialRisks']
        }
        tableHeader={(
          <ButtonGroup>
            <Button
              sx={{ '&:hover': { background: theme.palette.primary.main } }}
              variant={currentRetainTab.value === RetainTab.NO_RISK ? 'contained' : 'outlined'}
              onClick={() => setCurrentOwnerRiskTab(RetainTab.NO_RISK)}
            >
              {l['retention.tab.noRisk']}
              {': '}
              {ownerRiskCounts.noRisk}
            </Button>
            <Button
              sx={{ '&:hover': { background: theme.palette.primary.main } }}
              variant={currentRetainTab.value === RetainTab.NEW ? 'contained' : 'outlined'}
              onClick={() => setCurrentOwnerRiskTab(RetainTab.NEW)}
            >
              {l['retention.tab.new']}
              {': '}
              {ownerRiskCounts.new}
            </Button>
            <Button
              sx={{ '&:hover': { background: theme.palette.primary.main } }}
              variant={currentRetainTab.value === RetainTab.IN_PROGRESS ? 'contained' : 'outlined'}
              onClick={() => setCurrentOwnerRiskTab(RetainTab.IN_PROGRESS)}
            >
              {l['retention.tab.inProgress']}
              {': '}
              {ownerRiskCounts.inProgress}
            </Button>
            <Button
              sx={{ '&:hover': { background: theme.palette.primary.main } }}
              variant={currentRetainTab.value === RetainTab.RESOLVED ? 'contained' : 'outlined'}
              onClick={() => setCurrentOwnerRiskTab(RetainTab.RESOLVED)}
            >
              {l['retention.tab.resolved']}
              {': '}
              {ownerRiskCounts.resolved}
            </Button>
          </ButtonGroup>
        )}
        pageHeader={<Widgets />}
      />
      <Dialogs
        errorCreatingRiskRecommendation={errorCreatingRiskRecommendation}
        generateEmailGoBack={() => {
          setGenerateEmailDialogOpen(false);
          setActDialogOpen(true);
        }}
        generateEmailDialogOpen={generateEmailDialogOpen}
        setGenerateEmailDialogOpen={setGenerateEmailDialogOpen}
        resolveDialogOpen={resolveDialogOpen}
        setResolveDialogOpen={((open, reset = true) => {
          setResolveDialogOpen(open);

          if (!open && reset) {
            // to avoid a UI glitch where the spinner is shown because the selectedOwnerRisk becomes null
            setTimeout(() => {
              selectedOwnerRisk.value = null;
            }, 200);
          }
        })}
        actDialogOpen={actDialogOpen}
        setActDialogOpen={(open, reset = true) => {
          setActDialogOpen(open);

          if (!open && reset) {
            // to avoid a UI glitch where the spinner is shown because the selectedOwnerRisk becomes null
            setTimeout(() => {
              selectedOwnerRisk.value = null;
            }, 200);
          }
        }}
        forceActLoading={forceActButtonLoading}
      />
    </Stack>
  );
};

const selectedOwnerRisk = signal<{ id: string, hasExistingRecommendation: boolean } | null>(null);

const Dialogs = ({
  generateEmailDialogOpen,
  setGenerateEmailDialogOpen,
  resolveDialogOpen,
  setResolveDialogOpen,
  actDialogOpen,
  setActDialogOpen,
  forceActLoading,
  generateEmailGoBack,
  errorCreatingRiskRecommendation,
}: {
  generateEmailDialogOpen: boolean,
  setGenerateEmailDialogOpen: (v: boolean) => void,
  resolveDialogOpen: boolean,
  setResolveDialogOpen: (v: boolean, reset?: boolean) => void,
  actDialogOpen: boolean,
  setActDialogOpen: (v: boolean, reset?: boolean) => void,
  forceActLoading: boolean,
  generateEmailGoBack: () => void,
  errorCreatingRiskRecommendation: boolean,
}) => {
  const {
    data: ownerRisk,
    isLoading: ownerRiskLoading,
  } = useGetOwnerRisk(selectedOwnerRisk.value?.id ?? '', !!selectedOwnerRisk.value);
  const [selectedMitigations, setSelectedMitigations] = useState<OwnerRiskMitigation[]>([]);

  useEffect(() => {
    if (!ownerRisk) return;

    setSelectedMitigations(ownerRisk.recommendation?.mitigations ?? []);
  }, [setSelectedMitigations, ownerRisk]);

  return (
    <>
      <ResolveDialog ownerRiskID={ownerRisk?.id} open={resolveDialogOpen} onClose={() => setResolveDialogOpen(false)} />
      <ActDialog
        open={actDialogOpen}
        forceLoading={forceActLoading}
        onClose={() => setActDialogOpen(false)}
        ownerRisk={ownerRisk ?? null}
        ownerRiskLoading={ownerRiskLoading}
        hasExistingRecommendation={selectedOwnerRisk.value?.hasExistingRecommendation ?? false}
        openGenerateEmailDialog={() => {
          setGenerateEmailDialogOpen(true);
          setActDialogOpen(false, false);
        }}
        selectedMitigations={selectedMitigations}
        setSelectedMitigations={setSelectedMitigations}
        errorCreatingRiskRecommendation={errorCreatingRiskRecommendation}
      />
      <GenerateEmailDialog
        ownerRisk={ownerRisk!}
        open={generateEmailDialogOpen}
        onClose={() => setGenerateEmailDialogOpen(false)}
        goBack={generateEmailGoBack}
      />
    </>
  );
};
