import { useId } from 'react';

import {
  Activity, SuggestedPartnerStatus, useAnalytics,
} from 'lib';
import {
  UpDownArrows, useLabels,
} from 'ui';
import {
  Button, ButtonGroup, MenuItem, Select, Stack, TextField, useTheme,
} from '@mui/material';

import { partnerStatusState } from './state';
import { SuggestedPartner } from '../../api/suggested-partners/types';

export const Header = ({
  search,
  setSearch,
  sortOption,
  setSortOption,
  sortOptions,
  suggestedPartners,
  enabled = false,
}: {
  search: string,
  setSearch: (search: string) => void,
  sortOption: SortOption,
  setSortOption: (sortOption: SortOption) => void,
  sortOptions: SortOption[],
  suggestedPartners: SuggestedPartner[],
  enabled?: boolean
}) => {
  const l = useLabels();
  const theme = useTheme();
  const analytics = useAnalytics();
  const componentID = useId();

  const inProgressPartners = suggestedPartners.filter((partner) => partner.status === SuggestedPartnerStatus.IN_PROGRESS);
  const lostPartners = suggestedPartners.filter((partner) => partner.status === SuggestedPartnerStatus.LOST);

  const setPartnerStatus = (status: SuggestedPartnerStatus) => {
    partnerStatusState.value = status;
  };

  const handleSelectSortOption = (value: SortOption) => {
    analytics.track('Selector Changed', {
      selectorName: 'Sort',
      value,
    });

    setSortOption(value);
  };

  return (
    <Stack p={4} zIndex={1200}>
      <Stack direction="row" justifyContent="space-between" alignItems="center" flexWrap="wrap" gap={2}>
        <ButtonGroup>
          <Button
            sx={{ '&:hover': { background: theme.palette.primary.main } }}
            variant={partnerStatusState.value === SuggestedPartnerStatus.NEW ? 'contained' : 'outlined'}
            onClick={() => setPartnerStatus(SuggestedPartnerStatus.NEW)}
            disabled={!enabled}
          >
            {l['partners.status.suggested']}
          </Button>
          <Button
            sx={{ '&:hover': { background: theme.palette.primary.main } }}
            variant={partnerStatusState.value === SuggestedPartnerStatus.IN_PROGRESS ? 'contained' : 'outlined'}
            onClick={() => setPartnerStatus(SuggestedPartnerStatus.IN_PROGRESS)}
            disabled={!enabled}
          >
            {l['partners.status.inProgress']}
            {enabled && !!inProgressPartners.length && (
              <>
                {': '}
                {inProgressPartners.length}
              </>
            )}
          </Button>
          <Button
            sx={{ '&:hover': { background: theme.palette.primary.main } }}
            variant={partnerStatusState.value === SuggestedPartnerStatus.LOST ? 'contained' : 'outlined'}
            onClick={() => setPartnerStatus(SuggestedPartnerStatus.LOST)}
            disabled={!enabled}
          >
            {l['partners.status.notRelevant']}
            {enabled && !!lostPartners.length && (
              <>
                {': '}
                {lostPartners.length}
              </>
            )}
          </Button>
        </ButtonGroup>
        <Stack direction="row" alignItems="center" flexWrap="wrap" gap={2}>
          <TextField
            size="small"
            label={l['partners.searchPartner']}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            disabled={!enabled}
          />
          <Select
            disabled={!enabled}
            size="small"
            inputProps={{ IconComponent: () => null }}
            value={sortOption}
            onChange={(e) => handleSelectSortOption(e.target.value as SortOption)}
            sx={{ '&& .MuiSelect-select': { px: `${theme.spacing(3)} !important` } }}
            renderValue={() => (
              <Stack direction="row" alignItems="center" gap={1}>
                <UpDownArrows
                  height={24}
                  width={24}
                  fill={theme.palette.text.primary}
                />
                <span>{`${l.sortBy}: ${sortOption}`}</span>
              </Stack>
            )}
          >
            <MenuItem value="" disabled>{l.sortBy}</MenuItem>
            {sortOptions.map((option) => (
              <MenuItem key={`${componentID}-${option}`} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        </Stack>
      </Stack>
    </Stack>
  );
};

const sortByTopPicks = (a: SuggestedPartner, b: SuggestedPartner) => {
  // Handle null or undefined relevancy values, treat nulls as larger so they appear last
  if (a.relevancy === null || a.relevancy === undefined) return 1;
  if (b.relevancy === null || b.relevancy === undefined) return -1;

  // ascending relevancy
  if (a.relevancy !== b.relevancy) {
    return a.relevancy - b.relevancy;
  }

  const levelSort = sortByActivityLevel(a, b);
  if (levelSort !== 0) return levelSort;

  // descending years of experience
  return (b.experienceInYears ?? 0) - (a.experienceInYears ?? 0);
};

const sortByActivityLevel = (a: SuggestedPartner, b: SuggestedPartner) => {
  const activities = [Activity.LOW, Activity.MEDIUM, Activity.HIGH];
  const aIdx = a.activity ? activities.indexOf(a.activity) : -1;
  const bIdx = b.activity ? activities.indexOf(b.activity) : -1;

  if (aIdx !== bIdx) return bIdx - aIdx;

  return 0;
};

export enum SortOption {
  TOP_PICKS = 'Top Picks',
  ACTIVITY_LEVEL = 'Activity Level',
  YEARS_OF_EXPERIENCE = 'Years of Experience',
  A_TO_Z = 'A to Z',
  Z_TO_A = 'Z to A',
  DOOR_COUNT = 'Door Count',
}

export const sortOptionToFunction: Record<SortOption, (a: SuggestedPartner, b: SuggestedPartner) => number> = {
  [SortOption.TOP_PICKS]: sortByTopPicks,
  [SortOption.ACTIVITY_LEVEL]: sortByActivityLevel,
  [SortOption.YEARS_OF_EXPERIENCE]: (a, b) => (b.experienceInYears ?? 0) - (a.experienceInYears ?? 0),
  [SortOption.A_TO_Z]: (a, b) => a.name.localeCompare(b.name),
  [SortOption.Z_TO_A]: (a, b) => b.name.localeCompare(a.name),
  [SortOption.DOOR_COUNT]: (a, b) => b.doorCount - a.doorCount,
};
