import { useEffect } from 'react';

import {
  AddLeadData,
  addLeadFormSchema,
  useAnalytics, useHideChat,
} from 'lib';
import { Controller, useForm } from 'react-hook-form';
import { MdClose } from 'react-icons/md';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  TextField,
  useMediaQuery,
  useTheme,
} from '@mui/material';

import { InvertedButton } from '../button/styled';
import { AutocompletePrediction, GoogleAutocomplete } from '../google-autocomplete';
import { ReactComponent as PlusSign } from '../icons/svg/plus-sign.svg';
import { useLabels } from '../lib/translations';
import { Spinner } from '../spinner';
import { BoldTypography } from '../typography/BoldTypography';

export const NewLeadDialog = ({
  open,
  onClose,
  onInvite,
  isLoading,
}: {
  open: boolean,
  onClose: () => void,
  onInvite: (data: AddLeadData) => void,
  isLoading: boolean,
}) => {
  const l = useLabels();
  const theme = useTheme();
  const analytics = useAnalytics();
  const isLowerThanMd = useMediaQuery(theme.breakpoints.down('md'));

  useHideChat(open && isLowerThanMd);

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    watch,
    setValue,
  } = useForm<AddLeadData>({
    resolver: zodResolver(addLeadFormSchema),
    defaultValues: {
      email: '',
      firstName: '',
      lastName: '',
      addresses: [undefined],
      requestRentalAnalysis: false,
    },
  });

  const addresses = watch('addresses');

  const handleClickClose = (e: any, reason: 'backdropClick' | 'escapeKeyDown' | 'buttonClick') => {
    if (reason === 'backdropClick') return;

    analytics.track('Button Clicked', {
      buttonName: 'Close',
    });

    onClose();
  };

  const handleClickAdd = async () => {
    analytics.track('Button Clicked', {
      buttonName: 'Add Lead',
    });

    handleSubmit(onInvite)();
  };

  useEffect(() => {
    if (!open) reset();

    return () => {
      reset();
    };
  }, [open]);

  const spinner = (
    <>
      &nbsp;
      <Spinner size={18} />
    </>
  );

  return (
    <Dialog open={open} onClose={handleClickClose} fullScreen={isLowerThanMd} fullWidth maxWidth="md" disableEscapeKeyDown>
      <DialogTitle sx={{ borderBottom: `1px solid ${theme.palette.divider}`, py: 2 }}>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <BoldTypography variant="h6">
            {l['referral-partners.add.title']}
          </BoldTypography>
          <IconButton onClick={() => handleClickClose({}, 'buttonClick')}>
            <MdClose />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent sx={{ p: `${theme.spacing(4)} !important` }}>
        <Stack gap={4}>
          <Stack direction="row" gap={2} justifyContent="space-between">
            <Stack direction="column" width="100%">
              <BoldTypography variant="body1" pb={4}>
                {l['referral-partners.add.firstName']}
              </BoldTypography>
              <Controller
                name="firstName"
                control={control}
                render={({ field }) => (
                  <TextField
                    fullWidth
                    label={l['referral-partners.add.firstName']}
                    variant="outlined"
                    {...field}
                    error={!!errors.firstName}
                    helperText={errors.firstName?.message}
                  />
                )}
              />
            </Stack>
            <Stack direction="column" width="100%">
              <BoldTypography variant="body1" pb={4}>
                {l['referral-partners.add.lastName']}
              </BoldTypography>
              <Controller
                name="lastName"
                control={control}
                render={({ field }) => (
                  <TextField
                    fullWidth
                    label={l['referral-partners.add.lastName']}
                    variant="outlined"
                    {...field}
                    error={!!errors.lastName}
                    helperText={errors.lastName?.message}
                  />
                )}
              />
            </Stack>
          </Stack>
          <Stack direction="row" gap={2} justifyContent="space-between">
            <Stack direction="column" width="100%">
              <BoldTypography variant="body1" pb={4}>
                {l['referral-partners.add.phoneNumber']}
              </BoldTypography>
              <Controller
                name="phone"
                control={control}
                render={({ field }) => (
                  <TextField
                    fullWidth
                    label={l['referral-partners.add.phoneNumber']}
                    variant="outlined"
                    {...field}
                    error={!!errors.phone}
                    helperText={errors.phone?.message}
                  />
                )}
              />
            </Stack>
            <Stack direction="column" width="100%">
              <BoldTypography variant="body1" pb={4}>
                {l['referral-partners.add.email']}
              </BoldTypography>
              <Controller
                name="email"
                control={control}
                render={({ field }) => (
                  <TextField
                    label={l['referral-partners.add.email']}
                    type="email"
                    variant="outlined"
                    fullWidth
                    {...field}
                    error={!!errors.email}
                    helperText={errors.email?.message}
                  />
                )}
              />
            </Stack>
          </Stack>
          <Stack direction="column" width="100%">
            <BoldTypography variant="body1" pb={4}>
              {l['referral-partners.add.propertyAddress']}
            </BoldTypography>
            {addresses.map((address, index) => (
              <Stack pb={4} key={address?.place_id || index}>
                <GoogleAutocomplete
                  selectedOption={address || null}
                  setSelectedOption={(googleAddress: AutocompletePrediction | null) => {
                    setValue('addresses', [...addresses.slice(0, index), googleAddress, ...addresses.slice(index + 1)]);
                  }}
                  placeHolderLabel={l['referral-partners.add.propertyAddress']}
                  autoCompleteTrackingName="Address Autocomplete Create Lead popup"
                />
              </Stack>
            ))}
          </Stack>
          <Button
            sx={{
              color: 'grey',
            }}
            size="small"
            variant="outlined"
            onClick={() => { setValue('addresses', [...addresses, undefined]); }}
            startIcon={<PlusSign />}
          >
            {l.addAddress}
          </Button>
        </Stack>
      </DialogContent>
      <DialogActions sx={{ borderTop: `1px solid ${theme.palette.divider}`, py: 3 }}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%" pt={3} gap={2}>
          <Button
            size="small"
            variant="text"
            onClick={() => handleClickClose({}, 'buttonClick')}
          >
            {l.cancel}
          </Button>
          <InvertedButton
            size="small"
            onClick={handleClickAdd}
            disabled={isLoading}
          >
            {isLoading ? spinner : l.refer}
          </InvertedButton>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};
