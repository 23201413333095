import {
  countyData,
  ManagedPropertyType, useAnalytics,
} from 'lib';
import { UseFormReturn } from 'react-hook-form';
import { ControlledFormattedNumberField, FallbackSpinner, useLabels } from 'ui';
import {
  Divider, Stack, Typography, useTheme,
} from '@mui/material';

import { GrowthPolicyAutocompleteField } from './GrowthPolicyAutocompleteField';
import { StyledField } from './styled';
import { GrowthPolicyData } from '../../api/suggested-partners/forms';

export const OwnerLeadProfileForm = ({ form }: { form: UseFormReturn<GrowthPolicyData> }) => {
  const l = useLabels();
  const analytics = useAnalytics();
  const theme = useTheme();

  const typeOfPropertiesManagedOptions = [
    { label: l['partners.wizard.question.typeOfPropertiesManaged.singleFamily'], value: ManagedPropertyType.SINGLE_FAMILY },
    { label: l['partners.wizard.question.typeOfPropertiesManaged.townhome'], value: ManagedPropertyType.TOWNHOME },
    {
      label: l['partners.wizard.question.typeOfPropertiesManaged.smallMultiFamily'],
      value: ManagedPropertyType.SMALL_MULTI_FAMILY,
    },
    { label: l['partners.wizard.question.typeOfPropertiesManaged.multiFamily'], value: ManagedPropertyType.MULTI_FAMILY },
    { label: l['partners.wizard.question.typeOfPropertiesManaged.condo'], value: ManagedPropertyType.CONDO },
    { label: l['partners.wizard.question.typeOfPropertiesManaged.section8'], value: ManagedPropertyType.SECTION_8 },
    { label: l['partners.wizard.question.typeOfPropertiesManaged.commercial'], value: ManagedPropertyType.COMMERCIAL },
    { label: l.all, value: ManagedPropertyType.ALL },
  ];

  const workingCounties = countyData.value.counties.map((county) => ({
    label: `${county.county}, ${county.state}`,
    value: county,
  }));

  if (countyData.value.isLoading) {
    return <FallbackSpinner />;
  }

  return (
    <Stack gap={5}>
      <Stack sx={{ borderRadius: '10px', border: `1px solid ${theme.palette.divider}` }}>
        <StyledField id="door-count">
          <Typography variant="body1">
            {l['partners.wizard.question.doorCount']}
          </Typography>
          <ControlledFormattedNumberField
            name="doorCount"
            control={form.control}
            fullWidth
            size="small"
            prefix=""
            showZero={false}
            error={!!form.formState.errors.doorCount}
            helperText={form.formState.errors.doorCount?.message}
            placeholder={l.amount}
            onValueChange={(val) => {
              if (val) form.trigger('doorCount');
            }}
            onBlur={() => {
              analytics.track('Input Changed', {
                inputName: 'Referral Program - Door Count',
                value: form.getValues('doorCount'),
              });
            }}
          />
        </StyledField>
        <Divider />
        <StyledField id="type-of-properties-managed">
          <Typography variant="body1">
            {l['partners.wizard.question.typeOfPropertiesManaged']}
          </Typography>
          <GrowthPolicyAutocompleteField
            name="typeOfPropertiesManaged"
            form={form}
            options={typeOfPropertiesManagedOptions}
            placeholder={l.pleaseSpecify}
            normalizeValues={(values, details) => {
              if (details?.option.value === ManagedPropertyType.ALL) {
                return [ManagedPropertyType.ALL];
              }

              return values.map((o) => o.value).filter((v) => v !== ManagedPropertyType.ALL);
            }}
          />
        </StyledField>
        <Divider />
        <StyledField id="working-counties">
          <Typography variant="body1">
            {l['partners.wizard.question.workingCounties']}
          </Typography>
          <GrowthPolicyAutocompleteField
            name="workingCounties"
            form={form}
            options={workingCounties}
            getSelectedOptions={(values) => workingCounties.filter((county) => (
              values.findIndex((value) => value.county === county.value.county && value.state === county.value.state) !== -1
            ))}
            placeholder={l.pleaseSpecify}
          />
        </StyledField>
      </Stack>
      <Typography variant="body2" color="secondary" sx={{ px: { xs: 3, md: 10 }, textAlign: 'center' }}>
        {l['partners.wizard.step2.disclaimer']}
      </Typography>
    </Stack>
  );
};
