export const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '95%',
  maxWidth: 400,
  borderRadius: '6px',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 8,
  pt: 15,
  zIndex: 1800,
};
