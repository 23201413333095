import { useEffect, useState } from 'react';

import {
  GrowthPolicy,
  useAnalytics,
  useFeatures,
  useHideChat,
} from 'lib';
import {
  useForm, UseFormReturn,
} from 'react-hook-form';
import { MdClose } from 'react-icons/md';
import { toast } from 'react-toastify';
import {
  BoldTypography, EmptyFullPageContainer,
  InvertedButton, Spacer, Spinner, SpinnerWithLogo, useConfetti, useLabels,
} from 'ui';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  Button, Dialog, DialogActions, DialogContent, DialogTitle,
  IconButton,
  Stack, Step,
  StepLabel, Stepper,
  useMediaQuery, useTheme,
} from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';

import { OwnerLeadProfileForm } from './OwnerLeadProfileForm';
import { ReferralLandingPageForm } from './ReferralLandingPageForm';
import { ReferralProgramForm } from './ReferralProgramForm';
import { StyledColorConnector, StyledStepIcon } from './styled';
import {
  useCreateGrowthPolicy, useGetGrowthPolicy, useUpdateGrowthPolicy,
} from '../../api/growth-policy';
import { GrowthPolicyData, growthPolicySchema } from '../../api/suggested-partners/forms';
import { QueryKey } from '../../types/enums';

const createFormDefaultValues = (policy: GrowthPolicy | null | undefined): GrowthPolicyData => ({
  agentReferralFeeAmount: policy?.agentReferralFeeAmount ?? undefined,
  agentProvidedBenefits: policy?.agentProvidedBenefits ?? [],
  agentProvidedBenefitsOther: policy?.agentProvidedBenefitsOther ?? undefined,
  enableOwnerReferralProgram: policy?.enableOwnerReferralProgram ?? null,
  ownerReferralsIncentive: policy?.ownerReferralsIncentive ?? null,
  enablePMReferralProgram: policy?.enablePMReferralProgram ?? null,
  pmReferralsFeeAmount: policy?.pmReferralsFeeAmount ?? undefined,

  typeOfPropertiesManaged: policy?.typeOfPropertiesManaged ?? [],
  doorCount: policy?.doorCount ?? 0,
  workingCounties: policy?.workingCounties ?? [],

  companyDescription: policy?.companyDescription ?? '',
  pmAvatarURL: policy?.pmAvatarURL ?? '',
  pmFirstName: policy?.pmFirstName ?? '',
  pmLastName: policy?.pmLastName ?? '',
  pmTitle: policy?.pmTitle ?? '',
  companyGoogleReviews: policy?.companyGoogleReviews ?? 0,
  companyAVGDayOnMarket: policy?.companyAVGDayOnMarket ?? 0,
  companyTenantRenewalRate: policy?.companyTenantRenewalRate ?? 0,
  companyServices: policy?.companyServices ?? [],
  companyServicesOther: policy?.companyServicesOther ?? '',
  companyGuarantees: policy?.companyGuarantees ?? [],
  companyGuaranteesOther: policy?.companyGuaranteesOther ?? '',
  yearsInBusiness: policy?.yearsInBusiness ?? 0,
});

export const GrowthPolicyDialog = ({
  open,
  onClose,
}: {
  open: boolean,
  onClose: () => void,
}) => {
  const l = useLabels();
  const theme = useTheme();
  const analytics = useAnalytics();
  const isLowerThanMd = useMediaQuery(theme.breakpoints.down('md'));
  const { data: growthPolicy, isLoading: isLoadingGrowthPolicy } = useGetGrowthPolicy();

  useHideChat(open && isLowerThanMd);

  const [activeStep, setActiveStep] = useState(0);

  const form = useForm<GrowthPolicyData>({
    resolver: zodResolver(growthPolicySchema),
    defaultValues: createFormDefaultValues(growthPolicy),
  });
  const { reset } = form;

  const handleClickClose = (e: any, reason: 'backdropClick' | 'escapeKeyDown' | 'buttonClick') => {
    if (reason === 'backdropClick') return;

    analytics.track('Button Clicked', {
      buttonName: 'Close',
      partnerWizard: true,
    });

    onClose();
  };

  const steps = [
    {
      label: l['partners.wizard.referralProgram'],
      content: <ReferralProgramForm form={form} />,
    },
    {
      label: l['partners.wizard.ownerLeadProfile'],
      content: <OwnerLeadProfileForm form={form} />,
    },
    {
      label: l['partners.wizard.referralLandingPage'],
      content: <ReferralLandingPageForm form={form} />,
    },
  ];

  useEffect(() => {
    if (growthPolicy) {
      reset(createFormDefaultValues(growthPolicy));
    }
  }, [!!growthPolicy, reset]);

  useEffect(() => {
    if (!open) {
      setActiveStep(0);
      reset(createFormDefaultValues(growthPolicy));
    }
  }, [open, setActiveStep]);

  return (
    <Dialog
      open={open}
      onClose={handleClickClose}
      fullScreen={isLowerThanMd}
      fullWidth
      maxWidth="md"
      disableEscapeKeyDown
      PaperProps={{ sx: { height: '100%' } }}
    >
      <DialogTitle sx={{ borderBottom: `1px solid ${theme.palette.divider}`, py: 2 }}>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <BoldTypography variant="h6">
            {l['partners.wizard']}
          </BoldTypography>
          <IconButton onClick={() => handleClickClose({}, 'buttonClick')}>
            <MdClose />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent sx={{ p: `${theme.spacing(4)} !important` }}>
        {isLoadingGrowthPolicy ? (
          <EmptyFullPageContainer>
            <SpinnerWithLogo />
          </EmptyFullPageContainer>
        ) : (
          <Stack gap={4}>
            <Stepper alternativeLabel activeStep={activeStep} color="primary.dark" connector={<StyledColorConnector />}>
              {steps.map((step) => (
                <Step key={step.label}>
                  <StepLabel StepIconComponent={StyledStepIcon}>{step.label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            <Spacer spacing={1} />
            {steps[activeStep]?.content}
          </Stack>
        )}
      </DialogContent>
      <GrowthPolicyDialogActions
        form={form}
        activeStepNumber={activeStep}
        setStep={setActiveStep}
        closeDialog={() => handleClickClose({}, 'buttonClick')}
        growthPolicy={growthPolicy}
      />
    </Dialog>
  );
};

const GrowthPolicyDialogActions = ({
  form,
  activeStepNumber,
  setStep,
  closeDialog,
  growthPolicy = undefined,
}: {
  form: UseFormReturn<GrowthPolicyData>,
  activeStepNumber: number,
  setStep: (step: number) => void,
  closeDialog: () => void,
  growthPolicy?: GrowthPolicy | null,
}) => {
  const l = useLabels();
  const theme = useTheme();
  const analytics = useAnalytics();
  const { isLoading: isLoadingFeatures, ...features } = useFeatures();

  type ValidSteps = 0 | 1 | 2 | 3;
  type StepName = 'referralProgram' | 'ownerLeadProfile' | 'referralLandingPage' | 'done';

  type Step = {
    stepNumber: ValidSteps,
    fields: (keyof GrowthPolicyData)[],
    name: StepName,
  };

  const steps: Record<StepName, Step> = {
    referralProgram: {
      stepNumber: 0,
      fields: features.isFreemiumPM ? [
        'enablePMReferralProgram',
        'pmReferralsFeeAmount',
      ] : [
        'agentReferralFeeAmount',
        'agentProvidedBenefits',
        'agentProvidedBenefitsOther',
        'enableOwnerReferralProgram',
        'ownerReferralsIncentive',
        'enablePMReferralProgram',
        'pmReferralsFeeAmount',
      ],
      name: 'referralProgram',
    },
    ownerLeadProfile: {
      stepNumber: 1,
      fields: [
        'typeOfPropertiesManaged',
        'doorCount',
        'workingCounties',
      ],
      name: 'ownerLeadProfile',
    },
    referralLandingPage: {
      stepNumber: 2,
      fields: [
        'companyDescription',
        'pmAvatarURL',
        'pmFirstName',
        'pmLastName',
        'pmTitle',
        'companyGoogleReviews',
        'companyAVGDayOnMarket',
        'companyTenantRenewalRate',
        'companyServices',
        'companyServicesOther',
        'companyGuarantees',
        'companyGuaranteesOther',
        'yearsInBusiness',
      ],
      name: 'referralLandingPage',
    },
    done: {
      stepNumber: 3,
      fields: [],
      name: 'done',
    },
  };

  const currentStep = Object.values(steps).find((s) => s.stepNumber === activeStepNumber);

  const queryClient = useQueryClient();
  const { mutateAsync: createGrowthPolicy, isLoading: isCreatingGrowthPolicy } = useCreateGrowthPolicy();
  const { mutateAsync: updateGrowthPolicy, isLoading: isUpdatingGrowthPolicy } = useUpdateGrowthPolicy();
  const [invalidatingQuery, setInvalidatingQuery] = useState(false);
  const confetti = useConfetti();

  const isLoading = isCreatingGrowthPolicy || isUpdatingGrowthPolicy || invalidatingQuery;

  const handleNextStep = async (nextStep: Step) => {
    if (!currentStep) return;

    const res = await Promise.all(currentStep.fields.map((field) => form.trigger(field)));

    const valid = res.every((r) => r);

    if (!valid) return;

    if (growthPolicy) {
      await updateGrowthPolicy({
        id: growthPolicy.id,
        ...form.getValues(),
      });
      setInvalidatingQuery(true);
      await queryClient.invalidateQueries([QueryKey.GROWTH_POLICY]);
      setInvalidatingQuery(false);
    }

    if (nextStep.name === steps.done.name) {
      analytics.track('Form Submitted', {
        formName: 'Growth Policy',
      });
    }

    if (!growthPolicy && nextStep.name === steps.done.name) {
      await createGrowthPolicy(form.getValues());
      setInvalidatingQuery(true);
      await queryClient.invalidateQueries([QueryKey.GROWTH_POLICY]);
      setInvalidatingQuery(false);

      closeDialog();

      toast.success(l['partners.wizard.savedSuccessfully']);
      if (features.isFreemium) {
        confetti.trigger();
      }

      return;
    }

    if (nextStep.name === steps.done.name) {
      setStep(0);
      closeDialog();
      toast.success(l['partners.wizard.savedSuccessfully']);
      return;
    }

    setStep(nextStep.stepNumber);
  };

  const handlePrevStep = (stepToGoTo: Step) => {
    setStep(stepToGoTo.stepNumber);
  };

  const loader = (
    <>
      &nbsp;
      <Spinner size={20} />
    </>
  );

  return !isLoadingFeatures && (
    <DialogActions sx={{ borderTop: `1px solid ${theme.palette.divider}`, py: 3 }}>
      <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%" pt={3} gap={2}>
        {activeStepNumber === steps.referralProgram.stepNumber && (
          <>
            <Button
              variant="text"
              onClick={() => {
                analytics.track('Button Clicked', {
                  buttonName: 'Cancel',
                  partnerWizard: true,
                });

                closeDialog();
              }}
            >
              {l.cancel}
            </Button>
            <InvertedButton
              onClick={() => {
                analytics.track('Button Clicked', {
                  buttonName: 'Next',
                  partnerWizard: true,
                  activeStep: activeStepNumber,
                });

                handleNextStep(steps.ownerLeadProfile);
              }}
            >
              {isLoading ? loader : l.next}
            </InvertedButton>
          </>
        )}
        {activeStepNumber === steps.ownerLeadProfile.stepNumber && (
          <>
            <Button
              variant="text"
              onClick={() => {
                analytics.track('Button Clicked', {
                  buttonName: 'Back',
                  partnerWizard: true,
                  activeStep: activeStepNumber,
                });

                handlePrevStep(steps.referralProgram);
              }}
            >
              {l.back}
            </Button>
            <InvertedButton
              onClick={() => {
                analytics.track('Button Clicked', {
                  buttonName: 'Next',
                  partnerWizard: true,
                  activeStep: activeStepNumber,
                });

                handleNextStep(steps.referralLandingPage);
              }}
            >
              {isLoading ? loader : l.next}
            </InvertedButton>
          </>
        )}
        {activeStepNumber === steps.referralLandingPage.stepNumber && (
          <>
            <Button
              variant="text"
              onClick={() => {
                analytics.track('Button Clicked', {
                  buttonName: 'Back',
                  partnerWizard: true,
                  activeStep: activeStepNumber,
                });

                handlePrevStep(steps.ownerLeadProfile);
              }}
            >
              {l.back}
            </Button>
            <InvertedButton
              onClick={() => {
                analytics.track('Button Clicked', {
                  buttonName: 'Save',
                  partnerWizard: true,
                  activeStep: activeStepNumber,
                });

                handleNextStep(steps.done);
              }}
            >
              {isLoading ? loader : l.save}
            </InvertedButton>
          </>
        )}
      </Stack>
    </DialogActions>
  );
};
