import { GraphQLResult } from '@aws-amplify/api-graphql';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { convertUserActions } from './converters';
import { config } from '../../config/config';
import { useAuth } from '../../hooks/useAuth';
import { QueryKey } from '../../types/enums';
import { UserActions } from '../../types/userActions';
import { queryGraphQL } from '../amplify';
import { GetUserActionsQuery } from '../API';
import { axiosWithPayloadContext, createHeaders } from '../axios';
import { getUserActions } from '../graphql/queries';

export const useGetUserActions = () => {
  const { user, getAccessTokenSilently } = useAuth();

  return useQuery([QueryKey.USER_ACTIONS], async (): Promise<UserActions> => {
    if (user?.actAs) {
      return {
        owner: user?.email ?? '',
        pm: user?.pm ?? '',
        viewedCashOutTooltip: true,
        viewedWelcomeToMarketplaceScreen: true,
      };
    }

    const token = await getAccessTokenSilently();
    const res = await queryGraphQL({
      query: getUserActions,
      authToken: token,
      variables: { owner: user?.id },
    }) as GraphQLResult<GetUserActionsQuery>;

    return convertUserActions(res.data?.getUserActions);
  }, { enabled: !!user?.id });
};

export const useMutateUserActions = () => {
  const { getAccessTokenSilently } = useAuth();
  const queryClient = useQueryClient();

  return useMutation(async (data: {
    viewedWelcomeToMarketplaceScreen?: boolean | undefined,
    viewedCashOutTooltip?: boolean | undefined
  }) => {
    const token = await getAccessTokenSilently();

    await axiosWithPayloadContext({
      url: `${config.apiBaseURL}/user-actions`,
      method: 'PATCH',
      headers: createHeaders(token),
      data,
    });

    await queryClient.invalidateQueries([QueryKey.USER_ACTIONS]);
  });
};
