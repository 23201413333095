export const listPropertyValuations = /* GraphQL */ `
    query listProperties($pmId: String!, $nextToken: String) {
        listDashboardPropertiesByPm(pmId: $pmId, nextToken: $nextToken) {
            nextToken
            items {
                id
                propertyValuation(sortDirection: DESC, limit: 1) {
                    items {
                        priceMean
                        updatedTime
                    }
                }
            }
        }
    }
`;

export const getNotificationTemplate = /* GraphQL */ `
    query getNotificationTemplate($id: ID!) {
        getNotificationTemplate(id: $id) {
            id
            template
        }
    }
`;

export const listBuyerLeads = /* GraphQL */ `
    query listLeads($pm: String!, $nextToken: String) {
        listBuyerLeadWorkflowItemsByPm(pm: $pm, nextToken: $nextToken) {
            nextToken
            items {
                id
                revealed
                createdTime
                updatedTime
                status
                propertyId
                askingPrice
                isPropertyVisible
                sellerAgentContact {
                    id
                    email
                    firstName
                    lastName
                    phoneNumber
                }
                buyerContact {
                    id
                    email
                    firstName
                    lastName
                    phoneNumber
                }
                address {
                    street1
                    street2
                    city
                    state
                    zipCode
                }
            }
        }
    }
`;

export const listBuyerLeadIDs = /* GraphQL */ `
    query listLeads($pm: String!, $nextToken: String) {
        listBuyerLeadWorkflowItemsByPm(pm: $pm, nextToken: $nextToken) {
            nextToken
            items {
                id
            }
        }
    }
`;

export const listSellerLeads = /* GraphQL */ `
    query listLeads($pm: String!, $nextToken: String) {
        listSellerLeadWorkflowItemsByPm(pm: $pm, nextToken: $nextToken) {
            nextToken
            items {
                id
                revealed
                createdTime
                updatedTime
                status
                askingPrice
                isPropertyVisible
                sellerContact {
                    id
                    email
                    firstName
                    lastName
                    phoneNumber
                }
                address {
                    street1
                    street2
                    city
                    state
                    zipCode
                }
            }
        }
    }
`;

export const listSellerLeadIDs = /* GraphQL */ `
    query listLeads($pm: String!, $nextToken: String) {
        listSellerLeadWorkflowItemsByPm(pm: $pm, nextToken: $nextToken) {
            nextToken
            items {
                id
            }
        }
    }
`;

export const listBuyBoxLeads = /* GraphQL */ `
    query listLeads($pm: String!, $nextToken: String) {
        listBuyBoxLeadWorkflowItemsByPm(pm: $pm, nextToken: $nextToken) {
            nextToken
            items {
                id
                timeline
                amount
                propertyType
                financing
                status
                revealed
                createdTime
                updatedTime
                leadContactDetails {
                    id
                    email
                    firstName
                    lastName
                    phoneNumber
                }
            }
        }
    }
`;

export const listBuyBoxLeadIDs = /* GraphQL */ `
    query listLeads($pm: String!, $nextToken: String) {
        listBuyBoxLeadWorkflowItemsByPm(pm: $pm, nextToken: $nextToken) {
            nextToken
            items {
                id
            }
        }
    }
`;

export const listOwnerLeads = /* GraphQL */ `
    query listLeads($pm: String!, $nextToken: String) {
        listOwnerLeadWorkflowItemsByPm(pm: $pm, nextToken: $nextToken) {
            nextToken
            items {
              id
              pm
              requestRentalAnalysis
              addresses
              status
              createdTime
              updatedTime
              ownerLead {
                partner {
                  id
                  phoneNumber
                  firstName
                  lastName
                }
              }
              lead {
                  id
                  email
                  firstName
                  lastName
                  phoneNumber
              }
            }
        }
    }
`;

export const listOwnerLeadIDs = /* GraphQL */ `
    query listLeads($pm: String!, $nextToken: String) {
        listOwnerLeadWorkflowItemsByPm(pm: $pm, nextToken: $nextToken) {
            nextToken
            items {
                id
            }
        }
    }
`;

export const listProspectLeads = /* GraphQL */ `
    query listLeads($pm: String!, $nextToken: String) {
        listProspectLeadWorkflowItemByPm(pm: $pm, nextToken: $nextToken) {
            nextToken
            items {
              id
              status
              lead {
                  id
                  email
                  firstName
                  lastName
                  phoneNumber
              }
            }
        }
    }
`;

export const listProspectLeadIDs = /* GraphQL */ `
    query listLeads($pm: String!, $nextToken: String) {
        listProspectLeadWorkflowItemByPm(pm: $pm, nextToken: $nextToken) {
            nextToken
            items {
                id
            }
        }
    }
`;

export const getOwnerRisksByPmQuery = /* GraphQL */ `
  query GetOwnerRisksByPM($pm: String!, $nextToken: String) {
    listOwnerRiskByPM(pm: $pm, nextToken: $nextToken) {
      nextToken
      items {
        id
        ownerID
        clientValue
        risk
        status
        severity
        resolvedDate
        resolvedReason
        recommendations {
          items {
            id
          }
        }
        insights {
          insightType
        }
        ownerDetails {
          firstName
          lastName
        }
        updatedTime
      }
    }
  }
`;

export const getOwnerRiskQuery = /* GraphQL */ `
  query GetOwnerRisk($id: ID!) {
    getOwnerRisk(id: $id) {
      id
      ownerID
      insights {
        id
        caseName
        propertyID
        ownerEmail
        level
        insightType
        context {
          Key
          Value
        }
      }
      recommendations {
        items {
          id
          mitigations
          summaryMarkdown
          positivePoints
          negativePoints
          emailHTML
        }
      }
    }
  }
`;

export const getRetentionPolicyByPm = /* GraphQL */ `
  query GetRetentionPolicyByPM($pm: String!) {
    getRetentionPolicyByPM(pm: $pm) {
      items {
        id
        maxFinancialIncentive
        giveIncentiveWillingness
        meetWillingness
        badOwnerPreferredMitigation
        goodOwnerPreferredMitigation
        writingStyles
      }
    }
  }
`;

export const getOwnerPropertiesQuery = /* GraphQL */ `
  query GetOwnerPropertiesQuery($owner: String!) {
    listDashboardPropertyByOwner(owner: $owner, limit: 500, filter: { deleted: { ne: true } }) {
      items {
        id
        property {
          units {
            items {
              id
              isVacant
            }
          }
        }
        address {
          street1
          street2
          city
          state
          zipCode
        }
        propertyValuation(sortDirection: DESC, limit: 1) {
          items {
            priceMean
            date
          }
        }
        metrics(sortDirection: DESC, limit: 1) {
          items {
            NOI
            cashFlow
            capRate
            isDataPartial
            currentVacancy
            valuation
            trailingQ1 {
              cashflow
              noi
              noiExpenses
              totalExpenses
            }
            trailingQ2 {
              cashflow
              noi
              noiExpenses
              totalExpenses
            }
            trailingQ3 {
              cashflow
              noi
              noiExpenses
              totalExpenses
            }
            trailingQ4 {
              cashflow
              noi
              noiExpenses
              totalExpenses
            }
          }
        }
      }
    }
  }
`;

export const listDashboardPropertyIDsFilterDeletedQuery = /* GraphQL */ `
    query listProperties($pm: String!, $nextToken: String) {
        listDashboardPropertiesByPm(pmId: $pm, nextToken: $nextToken, filter: {deleted: {ne: true}}, limit: 1000) {
            nextToken
            items {
                id
            }
        }
    }
`;

export const getGrowthPolicy = /* GraphQL */ `
  query GetGrowthPolicy($pm: ID!) {
    getGrowthPolicy(id: $pm) {
      id
      agentReferralFeeAmount
      agentProvidedBenefits
      agentProvidedBenefitsOther
      enableOwnerReferralProgram
      ownerReferralsIncentive
      enablePMReferralProgram
      pmReferralsFeeAmount

      typeOfPropertiesManaged
      doorCount
      workingCounties {
        state
        county
      }

      companyDescription
      pmAvatarURL
      pmFirstName
      pmLastName
      pmTitle
      companyGoogleReviews
      companyAVGDayOnMarket
      companyTenantRenewalRate
      companyServices
      companyServicesOther
      companyGuarantees
      companyGuaranteesOther
      yearsInBusiness
    }
  }
`;

export const listSuggestedPartnersByPm = /* GraphQL */ `
  query GetPmSuggestedPartners($pm: String!, $nextToken: String) {
    listSuggestedPartnerByPm(pm: $pm, nextToken: $nextToken, filter: {status: {ne: WON}}, limit: 500) {
      items {
        id
        firstName
        lastName
        email
        phone
        avatar
        rating
        officeName
        address
        website
        last12MonthsSales
        totalSales
        minPrice
        maxPrice
        status
        activity
        relevancy
        experienceInYears
        suggestedPartnerType
        suggestedPartnerPM
        doorCount
        propertyTypes
        createdTime
        contactEmailBody
        workingCounties {
          county
          state
        }
      }
      nextToken
    }
  }
`;

export const listNewSuggestedPartnerCount = /* GraphQL */ `
  query GetPmSuggestedPartners($pm: String!, $type: String!) {
    listSuggestedPartnerByPm(pm: $pm, suggestedPartnerType: {eq: $type}, filter: {status: {eq: NEW}}, limit: 100) {
      items {
        id
        suggestedPartnerType
      }
    }
  }
`;

export const listPartnersByPm = /* GraphQL */ `
    query MyQuery($pm: String!, $nextToken: String) {
      listPartnersByPm(pm: $pm, nextToken: $nextToken) {
        nextToken
        items {
          id
          referralAmount
          firstName
          lastName
          partnerType
          createdTime
          avatar
          email
          phoneNumber
          website
          address
          doorCount
          minPrice
          maxPrice
          experienceInYears
          propertyTypes
          workingCounties {
            county
            state
          }
          ownerLeadReferralLink{
            items {
                id
            }
          }
          ownerLead {
            items {
              id
              numOfProperties
              firstName
              lastName
              email
              phoneNumber
              createdTime
              addresses
              ownerLeadWorkflowItem {
                  items {
                      status
                      source
                      addresses
                  }
              }
            }
          }
        }
      }
    }
`;

export const getPmMetrics = /* GraphQL */ `
    query GetPMMetrics($pm: ID!) {
      getPMMetrics(id: $pm) {
        id
        usersMetrics {
          createdTime
          activatedUsers
          activatedUsersLastMonth
          invitedUsersLastMonth
          invitedUsers
          activeUsersByMonth {
            activeUsers
            month
            year
          }
        }
        leadMetrics {
          totalBuyBoxLeads
          totalBuyBoxLeadsValue
          createdTime
          totalBuyerLeads
          totalProspects
          totalProspectsValue
          totalBuyerLeadsValue
          totalReferrals
          totalSellerLeads
          totalReferralsValue
          totalSellerLeadsValue
        }
        createdTime
        communicationMetrics {
          createdTime
          emailsSentToPMUsersByMonth {
            emailsSent
            month
            year
          }
        }
        propertyMetrics {
          createdTime
          properties
          units
          unitsByMonth {
            units
            month
            year
          }
        }
        riskMetrics {
          totalNoRisk
          noRiskTotalValue
          totalResolved
          resolvedTotalValue
          totalInProgress
          inProgressTotalValue
          totalNeedsAttention
          needsAttentionTotalValue
          createdTime
        }
        updatedTime
      }
    }
`;
