import { useEffect, useState } from 'react';

import {
  PartnerType, useAnalytics, useFeatures, useHideChat,
} from 'lib';
import { Controller, useForm } from 'react-hook-form';
import { MdClose } from 'react-icons/md';
import { toast } from 'react-toastify';
import {
  BoldTypography, ControlledFormattedNumberField, InvertedButton, Spinner, useConfetti, useLabels,
} from 'ui';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, IconButton,
  InputLabel, MenuItem,
  Select, Stack, TextField, useMediaQuery,
  useTheme,
} from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';

import {
  AddPartnerData, addPartnerFormSchema, PartnerAlreadyExistsError, useCreatePartner,
} from '../../api/partners';
import { QueryKey } from '../../types/enums';

export const AddPartnerDialog = ({
  open,
  onClose,
  defaultReferralAmount,
}: {
  open: boolean,
  onClose: () => void,
  defaultReferralAmount: number,
}) => {
  const l = useLabels();
  const theme = useTheme();
  const analytics = useAnalytics();
  const { isLoading: isLoadingFeatures, ...features } = useFeatures();
  const isLowerThanMd = useMediaQuery(theme.breakpoints.down('md'));
  const queryClient = useQueryClient();
  const confetti = useConfetti();

  useHideChat(open && isLowerThanMd);

  const {
    control,
    watch,
    formState: { errors },
    handleSubmit,
    getValues,
    reset,
    setError,
  } = useForm<AddPartnerData>({
    resolver: zodResolver(addPartnerFormSchema),
    defaultValues: {
      email: '',
      firstName: '',
      lastName: '',
      partnerType: PartnerType.AGENT,
      referralAmount: defaultReferralAmount,
    },
  });
  const { mutateAsync: createPartner, isLoading: isCreatingPartner } = useCreatePartner();
  const [invalidatingQueries, setInvalidatingQueries] = useState(false);

  const handleClickClose = (e: any, reason: 'backdropClick' | 'escapeKeyDown' | 'buttonClick') => {
    if (reason === 'backdropClick') return;

    analytics.track('Button Clicked', {
      buttonName: 'Close',
      partnerWizard: true,
    });

    onClose();
  };

  const isLoading = isCreatingPartner || invalidatingQueries || isLoadingFeatures;

  const addPartner = async (data: AddPartnerData) => {
    try {
      await createPartner(data);
      setInvalidatingQueries(true);
      await queryClient.invalidateQueries([QueryKey.PARTNERS]);
      setInvalidatingQueries(false);
      toast.success(l['pm-dashboard.network.messageSuccessInviteNewPartner']);
      if (features.isFreemium) {
        confetti.trigger();
      }
      onClose();
    } catch (e) {
      if (e instanceof PartnerAlreadyExistsError) {
        setError('email', {
          type: 'manual',
          message: l['partners.add.error.partnerAlreadyExists'],
        });
      } else {
        console.error(e);
        toast.error(l['error.unknownError']);
        onClose();
      }
    }
  };

  const handleClickAdd = async () => {
    analytics.track('Button Clicked', {
      buttonName: 'Add Partner',
      partnerWizard: true,
    });

    handleSubmit(addPartner)();
  };

  useEffect(() => {
    if (!open) reset();
  }, [open]);

  useEffect(() => {
    if (defaultReferralAmount) reset({ ...getValues(), referralAmount: defaultReferralAmount });
  }, [defaultReferralAmount]);

  return (
    <Dialog open={open} onClose={handleClickClose} fullScreen={isLowerThanMd} fullWidth maxWidth="xs" disableEscapeKeyDown>
      <DialogTitle sx={{ borderBottom: `1px solid ${theme.palette.divider}`, py: 2 }}>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <BoldTypography variant="h6">
            {l['partners.add.title']}
          </BoldTypography>
          <IconButton onClick={() => handleClickClose({}, 'buttonClick')}>
            <MdClose />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent sx={{ p: `${theme.spacing(4)} !important` }}>
        <Stack gap={4}>
          <Controller
            name="email"
            control={control}
            rules={{ required: true, pattern: /^\S+@\S+$/i }}
            render={({ field }) => (
              <TextField
                autoComplete="off"
                label={l['partners.add.email']}
                type="email"
                variant="outlined"
                value={field.value}
                onChange={(e) => {
                  field.onChange(e.target.value);
                }}
                error={!!errors.email}
                helperText={errors.email?.message}
              />
            )}
          />
          <Controller
            name="firstName"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <TextField
                autoComplete="off"
                label={l['partners.add.firstName']}
                variant="outlined"
                value={field.value}
                onChange={(e) => {
                  field.onChange(e.target.value);
                }}
                error={!!errors.firstName}
                helperText={errors.firstName?.message}
              />
            )}
          />
          <Controller
            name="lastName"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <TextField
                autoComplete="off"
                label={l['partners.add.lastName']}
                variant="outlined"
                value={field.value}
                onChange={(e) => {
                  field.onChange(e.target.value);
                }}
                error={!!errors.lastName}
                helperText={errors.lastName?.message}
              />
            )}
          />
          <Controller
            name="partnerType"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <FormControl fullWidth>
                <InputLabel>{l['partners.add.category']}</InputLabel>
                <Select
                  label={l['partners.add.category']}
                  variant="outlined"
                  value={field.value}
                  onChange={(e) => field.onChange(e.target.value)}
                >
                  <MenuItem value={PartnerType.AGENT}>
                    {l['partners.add.category.agent']}
                  </MenuItem>
                  <MenuItem value={PartnerType.CONTRACTOR}>
                    {l['partners.add.category.contractor']}
                  </MenuItem>
                  <MenuItem value={PartnerType.LENDER}>
                    {l['partners.add.category.lender']}
                  </MenuItem>
                </Select>
              </FormControl>
            )}
          />
          <ControlledFormattedNumberField
            error={!!errors.referralAmount}
            helperText={errors.referralAmount ? l['partners.add.referralAmount.errorText'] : ''}
            name="referralAmount"
            label={l['partners.add.referralAmount']}
            control={control}
            useExternalValue
            externalValue={watch('referralAmount')}
            fullWidth
            onBlur={() => {
              analytics.track('Input Changed', {
                inputName: 'Referral Amount',
                value: getValues('referralAmount'),
              });
            }}
          />
        </Stack>
      </DialogContent>
      <DialogActions sx={{ borderTop: `1px solid ${theme.palette.divider}`, py: 3 }}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%" pt={3} gap={2}>
          <Button
            size="small"
            variant="text"
            onClick={() => handleClickClose({}, 'buttonClick')}
          >
            {l.cancel}
          </Button>
          <InvertedButton
            size="small"
            onClick={handleClickAdd}
            disabled={isLoading}
          >
            {(isLoading) ? (
              <>
                &nbsp;
                <Spinner size={20} />
              </>
            ) : l.add}
          </InvertedButton>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};
