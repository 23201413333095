import { useSearchParams } from 'react-router-dom';

import { GrowthPolicy, useFeatures } from 'lib';
import { FallbackSpinner, useLabels } from 'ui';
import { signal } from '@preact/signals-react';

import { Header, SortOption } from './Header';
import { SuggestedPartners } from './SuggestedPartners';
import { SuggestedPartner } from '../../api/suggested-partners/types';
import { ConfirmPMPartner } from '../../components/add-partner/ConfirmPMPartner';

const searchSignal = signal('');
const sortOptionSignal = signal<SortOption>(SortOption.YEARS_OF_EXPERIENCE);

export const PMsHeader = ({ suggestedPartners }: { suggestedPartners: SuggestedPartner[] }) => {
  const { isLoading, ...features } = useFeatures();

  return (
    <Header
      enabled={!isLoading && features.isGrowthPmPartnersEnabled}
      suggestedPartners={suggestedPartners}
      search={searchSignal.value}
      setSearch={(search) => { searchSignal.value = search; }}
      sortOption={sortOptionSignal.value}
      setSortOption={(sortOption) => { sortOptionSignal.value = sortOption; }}
      sortOptions={[
        SortOption.YEARS_OF_EXPERIENCE,
        SortOption.A_TO_Z,
        SortOption.Z_TO_A,
        SortOption.DOOR_COUNT,
      ]}
    />
  );
};

export const PropertyManagers = ({
  suggestedPartners,
  growthPolicy,
}: {
  suggestedPartners: SuggestedPartner[],
  growthPolicy: GrowthPolicy | null,
}) => {
  const l = useLabels();
  const { isLoading, ...features } = useFeatures();
  const [searchParams, setSearchParams] = useSearchParams();

  if (isLoading) return <FallbackSpinner />;

  const removeSuggestedPartnerId = () => {
    const updatedParams = new URLSearchParams(searchParams);
    updatedParams.delete('suggested_partner_id');
    setSearchParams(updatedParams);
  };

  const suggestedPartnerId = searchParams.get('suggested_partner_id');

  return (
    <>
      <ConfirmPMPartner
        suggestedPartners={suggestedPartners}
        suggestedPartnerId={suggestedPartnerId ?? ''}
        onClose={() => { removeSuggestedPartnerId(); }}
      />
      {' '}
      <SuggestedPartners
        disabledTitle={l['partners.pms.disabled.title']}
        disabledSubtitle={l['partners.pms.disabled.subtitle']}
        suggestedPartners={suggestedPartners}
        growthPolicy={growthPolicy}
        initialSearch={searchSignal.value}
        sortOption={sortOptionSignal.value}
        enabled={features.isGrowthPmPartnersEnabled}
      />
    </>

  );
};
