import { useState } from 'react';

import { useAnalytics } from 'lib';
import {
  BoldTypography, Checkmark, CircledIcon, InvertedButton, LazyImage,
  personAvatar1URL, personAvatar2URL, personAvatar3URL, personAvatar4URL, personAvatar5URL,
  recommendationBackgroundURL, UpgradeProductDialog, useLabels, yourGrowthImageURL,
} from 'ui';
import {
  Paper, Skeleton, Stack, useMediaQuery, useTheme,
} from '@mui/material';

const UserCard = ({ avatar, skew = false }: { avatar: string, skew?: boolean }) => {
  const iconSize = 16;
  const avatarSize = 43;
  const theme = useTheme();

  return (
    <Paper sx={{
      position: 'relative',
      p: 2,
      height: 'fit-content',
      top: skew ? 13 : 0,
    }}
    >
      <CircledIcon
        icon={<Checkmark color={theme.palette.primary.contrastText} />}
        color={theme.palette.success.main}
        size={iconSize}
        sx={{
          position: 'absolute',
          top: -iconSize / 2,
          right: -iconSize / 2,
        }}
      />
      <Stack gap={2}>
        <LazyImage img={avatar} style={{ height: avatarSize, width: avatarSize }} />
        <Stack gap={1}>
          <Skeleton variant="text" width={avatarSize * 0.75} height={10} />
          <Skeleton variant="text" width={avatarSize * 0.4} height={10} />
        </Stack>
      </Stack>
    </Paper>
  );
};

export const InviteOwnersBanner = () => {
  const l = useLabels();
  const theme = useTheme();
  const analytics = useAnalytics();
  const [dialogOpen, setDialogOpen] = useState(false);
  const isLowerThanMd = useMediaQuery(theme.breakpoints.down('md'));
  const hideAvatar4Breakpoint = useMediaQuery(theme.breakpoints.down(810));
  const hideAvatar3Breakpoint = useMediaQuery(theme.breakpoints.down(700));
  const hideSpritesBreakpoint = useMediaQuery(theme.breakpoints.down('sm'));

  const handleClickInviteOwner = () => {
    analytics.track('Button Clicked', {
      buttonName: 'Freemium Marketplace - Invite Owners',
    });

    setDialogOpen(true);
  };

  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-evenly"
        alignItems="center"
        width="100%"
        px={5}
        gap={3}
        mb={3}
        sx={{
          overflow: 'hidden',
          position: 'relative',
          borderRadius: '6px',
          background: `url(${recommendationBackgroundURL}) no-repeat center center`,
        }}
      >
        {!hideSpritesBreakpoint && (
          <Stack direction="row" gap={7} justifyContent="space-between" overflow="visible">
            <UserCard avatar={personAvatar1URL} />
            <UserCard skew avatar={personAvatar2URL} />
            {!hideAvatar3Breakpoint && <UserCard avatar={personAvatar3URL} />}
          </Stack>
        )}
        <Stack
          overflow="hidden"
          alignItems="center"
          justifyContent="center"
          gap={3}
          py={5}
        >
          <BoldTypography variant="body1" sx={{ textAlign: 'center' }}>{l['freemium.inviteOwnersBanner.title']}</BoldTypography>
          <InvertedButton onClick={handleClickInviteOwner}>{l.inviteOwners}</InvertedButton>
        </Stack>
        {!hideSpritesBreakpoint && (
          <>
            <Stack direction="row" height="100%" gap={7}>
              <Stack height="115%">
                <LazyImage img={yourGrowthImageURL} style={{ height: '120%', width: 200 }} />
              </Stack>
              {!hideAvatar4Breakpoint && (
                <Stack pt={13}>
                  <LazyImage img={personAvatar4URL} style={{ height: 40, width: 40 }} />
                </Stack>
              )}
            </Stack>
            {!isLowerThanMd && (
              <Stack position="absolute" p={3} top={0} right={0}>
                <LazyImage img={personAvatar5URL} style={{ height: 20, width: 20 }} />
              </Stack>
            )}
          </>
        )}
      </Stack>
      <UpgradeProductDialog open={dialogOpen} onClose={() => setDialogOpen(false)} />
    </>
  );
};
