import { Features, OwnerReferralsIncentive } from 'lib';

export const getReferAndEarnSubtitle = (pmName: string, incentive: OwnerReferralsIncentive) => {
  const ownerReferralIncentiveToNumberOfMonths = {
    [OwnerReferralsIncentive.NO_INCENTIVE]: 0,
    [OwnerReferralsIncentive.ZERO_MGMT_FEE_ONE_MONTH]: 1,
    [OwnerReferralsIncentive.ZERO_MGMT_FEE_TWO_MONTHS]: 2,
    [OwnerReferralsIncentive.ZERO_MGMT_FEE_THREE_MONTHS]: 3,
  };

  const baseText = `Introduce friends to ${pmName}`;
  if (incentive === OwnerReferralsIncentive.NO_INCENTIVE) {
    return baseText;
  }

  const monthOrMonths = incentive === OwnerReferralsIncentive.ZERO_MGMT_FEE_ONE_MONTH ? 'month' : 'months';

  return `${baseText} and earn ${ownerReferralIncentiveToNumberOfMonths[incentive].toString()
  } ${monthOrMonths} of management fee`;
};

export const getCraneName = (features: Omit<Features, 'isLoading'>) => {
  if (features.isCraneAlternativeTextEnabled) return 'NARPM';

  return 'Crane';
};
