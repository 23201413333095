import {
  Dispatch, ReactNode, useMemo, useState,
} from 'react';

import {
  getOwnerFromID,
  OwnerRiskMitigation, useAnalytics, useImpersonate, useTimer,
} from 'lib';
import {
  MdClose, MdEdit, MdOutlineRemoveRedEye, MdPhone,
  MdSave,
} from 'react-icons/md';
import { TiDelete } from 'react-icons/ti';
import Markdown from 'react-markdown';
import { toast } from 'react-toastify';
import remarkGfm from 'remark-gfm';
import {
  BoldTypography, Chip, CustomChipProps, DollarRect, EmptyFullPageContainer, formatDecimalToPercentage, formatNumberToCurrency,
  InfoTooltip,
  InvertedButton, MissingDataIcon, NegativePoint, PercentGreen, PersonSquareTopLeft, PositivePoint, recommendationBackgroundURL,
  RecommendationChartIcon, Spacer, Spinner, SpinnerWithLogo, TextSlider, useLabels,
} from 'ui';
import {
  Autocomplete,
  Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid,
  IconButton, lighten, Stack, TextField, Typography, useMediaQuery, useTheme,
} from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';

import { RecommendationFeedback } from './RecommendationFeedback';
import { useGenerateEmail, useUpdateRecommendationMitigations } from '../../../../api/owner-risk';
import { useGetOwnerPropertyData } from '../../../../api/properties';
import { ExpandedOwnerRisk, OwnerPropertyData } from '../../../../api/properties/types';
import { QueryKey } from '../../../../types/enums';
import { showOwnerRiskMovedToInProgressToast } from '../../state';

type RecommendationRow = {
  positiveInsight?: string | null | undefined
  negativeInsight?: string | null | undefined
};

export const ActDialog = ({
  open,
  onClose,
  ownerRisk,
  ownerRiskLoading,
  hasExistingRecommendation,
  openGenerateEmailDialog,
  selectedMitigations,
  setSelectedMitigations,
  errorCreatingRiskRecommendation,
  forceLoading = false,
}: {
  open: boolean,
  onClose: () => void,
  ownerRisk: ExpandedOwnerRisk | null,
  ownerRiskLoading: boolean,
  hasExistingRecommendation: boolean,
  forceLoading?: boolean,
  openGenerateEmailDialog: () => void,
  selectedMitigations: OwnerRiskMitigation[],
  setSelectedMitigations: Dispatch<React.SetStateAction<OwnerRiskMitigation[]>>,
  errorCreatingRiskRecommendation: boolean,
}) => {
  const l = useLabels();
  const theme = useTheme();
  const analytics = useAnalytics();
  const isLowerThanMd = useMediaQuery(theme.breakpoints.down('md'));
  const queryClient = useQueryClient();
  const { stopCountdown: timerStopped } = useTimer({ enabled: open && !hasExistingRecommendation, timeoutSeconds: 10 });
  const { data: propertyData, isLoading: propertyDataLoading } = useGetOwnerPropertyData(ownerRisk?.ownerID);
  const {
    mutateAsync: updateRecommendationMitigations,
    isLoading: isSavingRecommendationMitigations,
  } = useUpdateRecommendationMitigations();
  const {
    mutateAsync: generateEmail,
    isLoading: isGenerateEmailLoading,
  } = useGenerateEmail();
  const { mutateAsync: impersonateOwner, isLoading: isImpersonatingOwner } = useImpersonate();
  const { mutateAsync: impersonateOwnerForProforma, isLoading: isImpersonatingOwnerForProforma } = useImpersonate();
  const [forceGeneratingEmail, setForceGeneratingEmail] = useState(false);
  const isLoading = forceLoading || ownerRiskLoading || propertyDataLoading || (!hasExistingRecommendation && !timerStopped);
  const isGeneratingEmail = forceGeneratingEmail || isGenerateEmailLoading;
  const generateEmailDisabled = (
    isSavingRecommendationMitigations || isGeneratingEmail || errorCreatingRiskRecommendation
  );
  const generateEmailShowLoading = isSavingRecommendationMitigations || isGeneratingEmail;
  const isMitigationsChanged = selectedMitigations.join(',') !== (ownerRisk?.recommendation?.mitigations ?? []).join(',');

  const handleClickGenerateEmail = async () => {
    analytics.track('Button Clicked', {
      buttonName: 'Generate An Email - Act Popup',
      ownerID: ownerRisk!.ownerID,
      ownerRiskID: ownerRisk!.id,
      selectedMitigations: selectedMitigations.join(','),
    });

    if (generateEmailDisabled) return;

    try {
      await generateEmail({
        recommendationID: ownerRisk!.recommendation!.id,
        regenerate: isMitigationsChanged,
      });
      setForceGeneratingEmail(true);
      await queryClient.invalidateQueries([QueryKey.OWNER_RISK, ownerRisk!.id]);
      setForceGeneratingEmail(false);
      openGenerateEmailDialog();
    } catch (e) {
      console.error(e);

      toast.error(l['error.unknownError']);
    }
  };

  const handleClickClose = (_: {}, reason?: 'backdropClick' | 'escapeKeyDown' | 'buttonClick') => {
    if (reason === 'backdropClick') return;

    // only show the toast if the user actually exited the dialog, rather than opened the generate email dialog
    if (reason === 'buttonClick' && showOwnerRiskMovedToInProgressToast.value) {
      showOwnerRiskMovedToInProgressToast.value = false;
      toast.success(l['retention.ownerRisk.movedToInProgress']);
    }

    onClose();
  };

  const handleClickImpersonate = async () => {
    const owner = getOwnerFromID(ownerRisk!.ownerID);

    analytics.track('User Impersonated', {
      actionName: l['retention.act.impersonate'],
      userEmail: owner.email,
      ownerRiskID: ownerRisk!.id,
      selectedMitigations: selectedMitigations.join(','),
    });

    try {
      const res = await impersonateOwner(getOwnerFromID(ownerRisk!.ownerID).email);
      window.open(`/?impersonation-token=${res.data.token}`, '_blank');
    } catch (e) {
      console.error(e);
      toast.error(l['error.unknownError']);
    }
  };

  const handleClickImpersonateForProforma = async () => {
    const owner = getOwnerFromID(ownerRisk!.ownerID);

    analytics.track('User Impersonated', {
      actionName: l['retention.act.impersonateForProforma'],
      userEmail: owner.email,
      ownerRiskID: ownerRisk!.id,
      selectedMitigations: selectedMitigations.join(','),
    });

    try {
      const res = await impersonateOwnerForProforma(getOwnerFromID(ownerRisk!.ownerID).email);
      window.open(`/proforma?impersonation-token=${res.data.token}`, '_blank');
    } catch (e) {
      console.error(e);
      toast.error(l['error.unknownError']);
    }
  };

  const saveSelectedMitigations = async (mitigations: OwnerRiskMitigation[]) => {
    if (mitigations.join(',') === ownerRisk!.recommendation?.mitigations?.join(',')) return;

    try {
      await updateRecommendationMitigations({
        id: ownerRisk!.recommendation!.id,
        userSelectedMitigations: mitigations,
        emailHTML: ownerRisk!.recommendation?.emailHTML ?? '',
      });
    } catch (e) {
      console.error(e);

      toast.error(l['error.unknownError']);
    }
  };

  const spinner = (
    <>
      &nbsp;
      <Spinner size={20} />
    </>
  );

  return (
    <Dialog
      open={open}
      onClose={handleClickClose}
      fullScreen={isLowerThanMd}
      fullWidth
      maxWidth="xl"
      PaperProps={{ sx: { height: '100%' } }}
      disableEscapeKeyDown
    >
      {!isLoading && (
        <DialogTitle sx={{ borderBottom: `1px solid ${theme.palette.divider}`, py: 2 }}>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <BoldTypography variant="h6">
              {l['retention.act.theAnalyzedResults']}
            </BoldTypography>
            <IconButton onClick={() => handleClickClose({}, 'buttonClick')}>
              <MdClose />
            </IconButton>
          </Stack>
        </DialogTitle>
      )}
      <DialogContent sx={{ pb: 3 }}>
        <Stack gap={4} height="100%">
          {isLoading ? (
            <EmptyFullPageContainer>
              <SpinnerWithLogo size={150} />
              <Spacer spacing={2} />
              {!hasExistingRecommendation && (
                <Box pt={10} pb={5} maxWidth={400}>
                  <TextSlider
                    color={theme.palette.primary.dark}
                    textSlides={[
                      l['retention.act.loadingPhrase1'],
                      l['retention.act.loadingPhrase2'],
                      l['retention.act.loadingPhrase3'],
                      l['retention.act.loadingPhrase4'],
                      l['retention.act.loadingPhrase5'],
                    ]}
                  />
                </Box>
              )}
            </EmptyFullPageContainer>
          ) : !errorCreatingRiskRecommendation && (
            <AnalyzedResults
              ownerRisk={ownerRisk!}
              propertyData={propertyData!}
              selectedMitigations={selectedMitigations}
              setSelectedMitigations={setSelectedMitigations}
              saveSelectedMitigations={saveSelectedMitigations}
            />
          )}
          {errorCreatingRiskRecommendation && (
            <Stack height="100%" width="100%" p={10}>
              <EmptyFullPageContainer>
                <MissingDataIcon iconProps={{ size: 40, color: theme.palette.error.main }} />
                <Spacer spacing={2} />
                <Typography variant="body1" sx={{ maxWidth: '50%', textAlign: 'center' }}>
                  {l['error.unknownError']}
                </Typography>
              </EmptyFullPageContainer>
            </Stack>
          )}
        </Stack>
      </DialogContent>
      {!isLoading && (
        <DialogActions sx={{ borderTop: `1px solid ${theme.palette.divider}`, py: 3 }}>
          <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%" pt={3} gap={2}>
            <Stack gap={2} direction="row">
              {ownerRisk && ownerRisk.ownerDetails && (
                <>
                  <MdPhone size={18} />
                  <Typography variant="body1">
                    {ownerRisk.ownerDetails.phoneNumber}
                  </Typography>
                </>
              )}
            </Stack>
            <Stack direction="row" alignItems="center" gap={2}>
              <Button
                disabled={isImpersonatingOwnerForProforma}
                variant="outlined"
                onClick={handleClickImpersonateForProforma}
                startIcon={!isImpersonatingOwnerForProforma && <MdEdit />}
              >
                {isImpersonatingOwnerForProforma ? spinner : l['retention.act.impersonateForProforma']}
              </Button>
              <Button
                disabled={isImpersonatingOwner}
                variant="outlined"
                onClick={handleClickImpersonate}
                startIcon={!isImpersonatingOwner && <MdOutlineRemoveRedEye />}
              >
                {isImpersonatingOwner ? spinner : l['retention.act.impersonate']}
              </Button>
              <InvertedButton
                onClick={handleClickGenerateEmail}
                disabled={generateEmailDisabled}
              >
                {generateEmailShowLoading ? spinner : l['retention.act.generateEmail']}
              </InvertedButton>
            </Stack>
          </Stack>
        </DialogActions>
      )}
    </Dialog>
  );
};

const useMitigationLabels = () => {
  const l = useLabels();

  return {
    [OwnerRiskMitigation.DISCOUNT_1_MONTH]: l['retention.act.discount1'],
    [OwnerRiskMitigation.DISCOUNT_2_MONTH]: l['retention.act.discount2'],
    [OwnerRiskMitigation.DISCOUNT_3_MONTH]: l['retention.act.discount3'],
    [OwnerRiskMitigation.SCHEDULE_MEETING]: l['retention.act.scheduleMeeting'],
    [OwnerRiskMitigation.ATTACH_VALUE_REPORT]: l['retention.act.attachValueReport'],
    [OwnerRiskMitigation.ATTACH_PREFORMA_REPORT]: l['retention.act.attachPreformaReport'],
    [OwnerRiskMitigation.LINK_TO_INVESTOR_DASHBOARD]: l['retention.act.linkToInvestorPlatform'],
    [OwnerRiskMitigation.LINK_TO_PROFORMA]: l['retention.act.linkToProforma'],
    [OwnerRiskMitigation.PUSH_TO_SELL]: l['retention.act.pushToSell'],
    [OwnerRiskMitigation.PUSH_TO_1031]: l['retention.act.pushTo1031'],
  };
};

const Mitigations = ({
  selectedMitigations,
  setSelectedMitigations,
}: {
  selectedMitigations: OwnerRiskMitigation[],
  setSelectedMitigations: Dispatch<React.SetStateAction<OwnerRiskMitigation[]>>,
}) => {
  const theme = useTheme();
  const analytics = useAnalytics();
  const mitigationLabels = useMitigationLabels();

  const options = [
    { label: mitigationLabels[OwnerRiskMitigation.DISCOUNT_1_MONTH], value: OwnerRiskMitigation.DISCOUNT_1_MONTH },
    { label: mitigationLabels[OwnerRiskMitigation.DISCOUNT_2_MONTH], value: OwnerRiskMitigation.DISCOUNT_2_MONTH },
    { label: mitigationLabels[OwnerRiskMitigation.DISCOUNT_3_MONTH], value: OwnerRiskMitigation.DISCOUNT_3_MONTH },
    { label: mitigationLabels[OwnerRiskMitigation.SCHEDULE_MEETING], value: OwnerRiskMitigation.SCHEDULE_MEETING },
    { label: mitigationLabels[OwnerRiskMitigation.LINK_TO_PROFORMA], value: OwnerRiskMitigation.LINK_TO_PROFORMA },
    {
      label: mitigationLabels[OwnerRiskMitigation.LINK_TO_INVESTOR_DASHBOARD],
      value: OwnerRiskMitigation.LINK_TO_INVESTOR_DASHBOARD,
    },
    { label: mitigationLabels[OwnerRiskMitigation.PUSH_TO_SELL], value: OwnerRiskMitigation.PUSH_TO_SELL },
    { label: mitigationLabels[OwnerRiskMitigation.PUSH_TO_1031], value: OwnerRiskMitigation.PUSH_TO_1031 },
  ];

  const selectedOptions = useMemo(() => options.filter((o) => selectedMitigations.includes(o.value)), [
    selectedMitigations,
  ]);

  return (
    <Autocomplete
      multiple
      options={options}
      value={selectedOptions}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      disableCloseOnSelect
      onChange={(_, values, reason, details) => {
        analytics.track('Autocomplete Options Changed', {
          autocompleteName: 'Mitigations',
          value: values.map((o) => o.value).join(','),
        });

        const selectedOption = details?.option;

        const mitigations = values.map((o) => o.value).filter((v, __, mappedValues) => {
          const discounts = [
            OwnerRiskMitigation.DISCOUNT_1_MONTH,
            OwnerRiskMitigation.DISCOUNT_2_MONTH,
            OwnerRiskMitigation.DISCOUNT_3_MONTH,
          ];

          const discountCount = discounts.filter((discount) => mappedValues.includes(discount)).length;

          return !discounts.includes(v) || discountCount <= 1 || selectedOption?.value === v;
        });

        setSelectedMitigations(mitigations);
      }}
      renderTags={(tagValue, getTagProps) => tagValue.map((option, index) => (
        <MitigationChip
          mitigation={option.value}
          deleteIcon={<TiDelete color={theme.palette.primary.contrastText} />}
          {...getTagProps({ index })}
        />
      ))}
      renderInput={(params) => (
        <TextField
          {...params}
          InputProps={{
            sx: {
              minWidth: 200,
              borderRadius: '4px',
            },
            ...params.InputProps,
          }}
        />
      )}
    />
  );
};

const MitigationChip = ({
  mitigation,
  showTooltip = false,
  ...props
}: {
  mitigation: OwnerRiskMitigation,
  showTooltip?: boolean
} & CustomChipProps) => {
  const l = useLabels();
  const theme = useTheme();
  const mitigationLabels = useMitigationLabels();

  const chip = (
    <Chip
      label={mitigationLabels[mitigation]}
      sx={{
        backgroundColor: lighten(theme.palette.primary.dark, 0.2),
        color: theme.palette.primary.contrastText,
        fontWeight: theme.typography.fontWeightMedium,
      }}
      {...props}
    />
  );

  if (showTooltip && mitigation === OwnerRiskMitigation.LINK_TO_PROFORMA) {
    return (
      <InfoTooltip
        arrow
        isLight
        title={(
          <Typography variant="body2" sx={{ p: 1 }}>
            {l['retention.act.linkToProforma.tooltip']}
          </Typography>
        )}
        icon={chip}
      />
    );
  }

  if (showTooltip && mitigation === OwnerRiskMitigation.LINK_TO_INVESTOR_DASHBOARD) {
    return (
      <InfoTooltip
        arrow
        isLight
        title={(
          <Typography variant="body2" sx={{ p: 1 }}>
            {l['retention.act.linkToInvestorPlatform.tooltip']}
          </Typography>
        )}
        icon={chip}
      />
    );
  }

  return chip;
};

const AnalyzedResults = ({
  ownerRisk,
  propertyData,
  selectedMitigations,
  setSelectedMitigations,
  saveSelectedMitigations,
}: {
  ownerRisk: ExpandedOwnerRisk,
  propertyData: OwnerPropertyData,
  selectedMitigations: OwnerRiskMitigation[],
  setSelectedMitigations: Dispatch<React.SetStateAction<OwnerRiskMitigation[]>>,
  saveSelectedMitigations: (mitigations: OwnerRiskMitigation[]) => Promise<void>,
}) => {
  const l = useLabels();
  const theme = useTheme();
  const analytics = useAnalytics();
  const [openEditMitigations, setOpenEditMitigations] = useState(false);
  const [maxShownInsightRows, setMaxShownInsightRows] = useState(3);

  const metrics = 5;
  const colWidth = 12 / metrics;
  const positiveInsights = ownerRisk.recommendation?.positivePoints;
  const negativeInsights = ownerRisk.recommendation?.negativePoints;

  const maxIndex = Math.max(positiveInsights?.length || 0, negativeInsights?.length || 0);

  const getRecommendationRows = () => {
    if (!positiveInsights || !negativeInsights) {
      return [];
    }

    const recommendationRows: RecommendationRow[] = [];
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < maxIndex; i++) {
      recommendationRows.push({
        positiveInsight: positiveInsights[i],
        negativeInsight: negativeInsights[i],
      });
    }
    return recommendationRows;
  };

  const insightRows: RecommendationRow[] = getRecommendationRows();

  const handleClickEditMitigations = async () => {
    analytics.track('Button Clicked', {
      buttonName: 'Edit Mitigations',
      ownerID: ownerRisk.ownerID,
    });

    setOpenEditMitigations((open) => !open);

    if (openEditMitigations) {
      await saveSelectedMitigations(selectedMitigations);
    }
  };

  const handleClickShowMoreInsights = () => {
    analytics.track('Button Clicked', {
      buttonName: 'Show More Insights',
      ownerID: ownerRisk.ownerID,
    });

    setMaxShownInsightRows((currMax) => currMax + 3);
  };

  return (
    <Stack gap={3} py={3}>
      <Stack sx={{
        backgroundColor: lighten(theme.palette.primary.dark, 0.88),
        pb: 3,
        pt: 1,
        px: 1,
        borderRadius: '10px',
        gap: 2,
      }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          p={8}
          gap={20}
          height="100%"
          borderRadius="10px"
          sx={{
            background: `url(${recommendationBackgroundURL}) no-repeat`,
            backgroundSize: 'cover',
          }}
        >
          <Stack gap={5} maxWidth="80%">
            <BoldTypography variant="h6">
              {l['retention.recommendation']}
            </BoldTypography>
            {ownerRisk.recommendation?.summaryMarkdown && (
              <Markdown remarkPlugins={[remarkGfm]}>
                {ownerRisk.recommendation?.summaryMarkdown}
              </Markdown>
            )}
          </Stack>
          <RecommendationChartIcon width={408} />
        </Stack>
        <Stack px={5} direction={{ xs: 'column-reverse', md: 'row' }} alignItems="center" justifyContent="space-between">
          <Stack direction="row" gap={2}>
            <Stack justifyContent="center">
              <Button
                variant="outlined"
                startIcon={openEditMitigations ? <MdSave /> : <MdEdit />}
                onClick={handleClickEditMitigations}
                sx={{ minWidth: 90 }}
              >
                {openEditMitigations ? l.save : l.edit}
              </Button>
            </Stack>
            {openEditMitigations ? (
              <Mitigations
                selectedMitigations={selectedMitigations}
                setSelectedMitigations={setSelectedMitigations}
              />
            ) : (
              <Stack
                direction="row"
                flexWrap="wrap"
                gap={1.5}
                pt={`calc(1px + ${theme.spacing(4)})`}
                pb={`calc(1px + ${theme.spacing(4)})`}
                pl={3}
                pr={`calc(1px + ${theme.spacing(4)})`}
              >
                {selectedMitigations.map((mitigation) => (
                  <MitigationChip showTooltip key={mitigation} mitigation={mitigation} />
                ))}
              </Stack>
            )}
          </Stack>
          <RecommendationFeedback ownerRisk={ownerRisk} feedbackName="Act Dialog - Mitigations" />
        </Stack>
      </Stack>
      <Stack borderRadius="10px" border={`1px solid ${theme.palette.divider}`} p={0} gap={0} direction="column">
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Stack
            height="100%"
            width="50%"
            direction="row"
            alignItems="center"
            gap={2}
            px={5}
            py={4}
            sx={{
              background: lighten(theme.palette.success.main, 0.88),
            }}
          >
            <Typography variant="body1">
              {l['retention.advantages']}
            </Typography>
          </Stack>
          <Stack
            height="100%"
            width="50%"
            direction="row"
            alignItems="center"
            gap={2}
            px={5}
            py={4}
            sx={{
              background: lighten(theme.palette.primary.dark, 0.88),
            }}
          >
            <Typography variant="body1">
              {l['retention.disadvantages']}
            </Typography>
          </Stack>
        </Stack>
        {insightRows.slice(0, maxShownInsightRows).map((insightRow) => (
          <Stack
            key={`${insightRow.positiveInsight}-${insightRow.negativeInsight}`}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ borderTop: `1px solid ${theme.palette.divider}` }}
          >
            {insightRow.positiveInsight && (
              <Stack
                height="100%"
                width="50%"
                key={insightRow.positiveInsight}
                direction="row"
                alignItems="center"
                gap={3}
                px={5}
                py={4}
              >
                <span><PositivePoint /></span>
                <Typography variant="body1">
                  {insightRow.positiveInsight}
                </Typography>
              </Stack>
            )}
            {insightRow.negativeInsight && (
              <Stack
                height="100%"
                width="50%"
                key={insightRow.negativeInsight}
                direction="row"
                alignItems="center"
                gap={3}
                px={5}
                py={4}
              >
                <span><NegativePoint /></span>
                <Typography variant="body1">
                  {insightRow.negativeInsight}
                </Typography>
              </Stack>
            )}
          </Stack>
        ))}
        {insightRows.length > maxShownInsightRows && (
          <>
            <Divider sx={{ m: 0 }} />
            <Stack width="100%" justifyContent="center" alignItems="center" py={2}>
              <Button
                variant="text"
                onClick={handleClickShowMoreInsights}
                sx={{ textTransform: 'none', color: theme.palette.primary.dark }}
              >
                {l.showMore}
              </Button>
            </Stack>
          </>
        )}
      </Stack>
      <Grid container spacing={3}>
        <Grid item xs={6} sm={4} md={colWidth}>
          <Metric
            icon={(
              <Stack
                justifyContent="center"
                alignItems="center"
                borderRadius="6px"
                p={1}
                sx={{ border: `1px solid ${theme.palette.divider}` }}
              >
                <DollarRect height={21} width={21} />
              </Stack>
            )}
            label={l.portfolioValue}
            value={propertyData.portfolioValue ? formatNumberToCurrency(propertyData.portfolioValue, 0) : l.na}
          />
        </Grid>
        <Grid item xs={6} sm={4} md={colWidth}>
          <Metric
            icon={<PersonSquareTopLeft color={theme.palette.success.main} size={31} />}
            label={l['retention.occupancy']}
            value={propertyData.unitCount
              ? formatDecimalToPercentage(propertyData.nonVacantUnits / propertyData.unitCount, 2)
              : l.na}
          />
        </Grid>
        <Grid item xs={6} sm={4} md={colWidth}>
          <Metric
            icon={<PercentGreen />}
            label={propertyData.isEstimated ? l.estimatedCapRate : l.capRate}
            value={propertyData.capRate ? formatDecimalToPercentage(propertyData.capRate, 2) : l.na}
          />
        </Grid>
        <Grid item xs={6} sm={4} md={colWidth}>
          <Metric
            icon={(
              <Stack
                justifyContent="center"
                alignItems="center"
                borderRadius="6px"
                p={1}
                sx={{ border: `1px solid ${theme.palette.divider}` }}
              >
                <DollarRect height={21} width={21} />
              </Stack>
            )}
            label={l.cashFlow}
            value={formatNumberToCurrency(propertyData.cashFlow, 0)}
          />
        </Grid>
      </Grid>
    </Stack>
  );
};

const Metric = ({ icon, label, value }: { icon: ReactNode, label: string, value: string }) => {
  const theme = useTheme();

  return (
    <Stack
      direction="row"
      alignItems="center"
      sx={{
        background: theme.palette.background.paper,
        p: 3,
        borderRadius: '6px',
        border: `1px solid ${theme.palette.divider}`,
        height: '100%',
      }}
      gap={{ xs: 2, md: 5 }}
    >
      {icon}
      <Stack direction="column" gap={2} justifyContent="center">
        <BoldTypography variant="h6">
          {value}
        </BoldTypography>
        <Typography variant="body2">
          {label}
        </Typography>
      </Stack>
    </Stack>
  );
};
